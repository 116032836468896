
import * as lib from '/front/js🧠🧠🧠/🔧utils.js'



// 💡💡💡
// Se puede utilizar el mismo del LDR, o crear uno diferente con el RVLPOP.js
// import {setRVL, initRVL } from '/front/comps🦾🦾🦾/Loader⏳/LDR'
import {setRVL, initRVL } from '/front/comps🦾🦾🦾/Loader⏳/RVLPOP.js'

// Fn para trincar el SKIN
import {getSkin} from '/front/start🏁🏁🏁/load🧱.js'


export  async function POPin(
	url = null,
	link = null,
  back = null,
  
){
  if(this.busy == 1){ return false }
  
  // const urlcheck = import.meta.env.DEV == true ? '' : 'http://192.168.68.113:1234/'
  const urlcheck = 'http://192.168.68.113:1234/'
	// url = url.replace(window.location.origin, '')
  const acturl = new URL(this.URL, urlcheck)
  const newurl = new URL(url, urlcheck)

  //💡 Si hay menú
  if(this.nav.isOpen == 1) await this.nav.closeFn(document.querySelector('.nav'))
  
  //💡 Si la URL es igual sin hash
  if(acturl.href.replace(newurl.hash,'') === newurl.href.replace(newurl.hash,'')){
    
    //💡 Si la URL contiene un hash
    if(newurl.hash && document.querySelector(newurl.hash)) this.lenis.scrollTo(newurl.hash,{ offset:-100, force:true })

    return false
  }


  //💡 Entramos en busy para que no puedas clicar
  this.busy = 1

  //💡 La barra de scroll, para no cambiarla tan brusca
  let duration = .45
  gsap.to(this.SLLbar,{progress:0,duration})


  if(import.meta.env.DEV == true){
    
    url = '.'+url
    url = url == './' ? 'http://192.168.1.113:1234/' : 'http://192.168.1.113:1234/'+url
  }

  window.history.pushState({}, document.title, url)
  
  this.URL = url



  this.lenis.stop()

  const global = Object.fromEntries(this.OPS)
  global.back = back

  //EL LOADER
  const POP = setRVL({global})

  // 💡💡💡 Aquí se podría hacer un promise para animaciones, como por ejemplo, 
  // si necesitas que un elemento de una vista se quede para la siguiente vista. 
  // const ANMpromise = new Promise((resolve, reject) => ANM(resolve))

  const POPpromise = new Promise((resolve, reject) => initRVL(POP,resolve))
  const HEADpromise = new Promise((resolve, reject) => getHEAD(this.URL,resolve))
  const DATApromise = new Promise((resolve, reject) => getDATA(global,this.URL,resolve))
  
  //promis
    //0 nothing
    //1 nothing
    //2 HTML
  const promis = await Promise.all([
    POPpromise,
    HEADpromise,
    DATApromise,
    // ANMpromise
  ])

  
  //Remove old main
	document.querySelector('main').remove()

  //SCROLL TO 0
  this.lenis.scrollTo(0, {immediate: true, lock: true, force: true})
  
  //new append
  await document.querySelector('#app').insertAdjacentElement('beforeend',promis[2].querySelector('main'))

  
  
  await Promise.all([
    this.setIMG(),
    this.setVID(),
  ])

  // RESET APP
  this.initApp(POP)

}

// GET DATA NEW VIEW
async function getDATA(global,url,resolve){

  const parser = new DOMParser()
  const DATA = await getSkin(url)
  console.log('get data')
  const html = parser.parseFromString(DATA, 'text/html')
  console.log(html)
    const removes = html.querySelectorAll('#videoLow,.waiter,.ldr,.nav,.head')

    console.log(html)
    for(let a of removes){
      a.remove()
    }
    console.log(html)
  resolve(html)
}


// GET HEAD NEW VIEW
async function getHEAD(url,resolve){

  const parser = new DOMParser()
  const request = await window.fetch(url, {
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
		},
	})
	const response = await request.text()
  const headinfo = document.createElement('div')
	headinfo.innerHTML = headinfo
  document.title = headinfo.title


  resolve()
}
