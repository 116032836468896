export function browserCheck(){
  //No memoria scroll
  if (window.history.scrollRestoration) {
    window.history.scrollRestoration = 'manual'
  }
  //🍫
  //--🪟 2
  //--🖼️ 1
  //🖥️ 0
  //📲 3

  // Tablets
  //el L-andscape ( 1 ) se trata como un desktop, recibe imágenes tamaño mobile ( < 1366 )
  //el P-ortrait ( 2 ) se trata como móvil, recibe imágenes < 1366
  
  //Mobile
  // Solo P-ortrait ( 3 ), coge imágenes portrait.


  //Imágenes
  // Si son mayores que 0 ( desktop ), coge el tamaño pequeño ( < 1366)




  // TOUCH
  //OLD
  // let touch = /Mobi|Andrdoid|Tablet|iPad|iPhone/.test(navigator.userAgent)||"MacIntel"===navigator.platform&&1<navigator.maxTouchPoints
  //NEW
  // let touch = window.matchMedia('(any-pointer:coarse)').matches
  let touch = window.matchMedia('(hover:none)').matches
  console.log(touch)

  const w = window.innerWidth
  const h = window.innerHeight
  let device = 0
  let  devicec = ''
  if(!touch){
    // devicec = 'desktop'
    device = 0

    if(w > 1780){
      device = -1
    }
  }
  else{
    // devicec = 'mobile'
    device = 3
    if(w > 767){
      if(w > h){
        // devicec = 'tabletL'
        device = 1
      }
      else{
        // devicec = 'tabletS'
        device = 2
      }
    }
    
  }
 
  
  const sUsrAg = navigator.userAgent
  if (sUsrAg.indexOf("Chrome") > -1) {

  } else if (sUsrAg.indexOf("Safari") > -1) {
    document.documentElement.classList.add('N-A') 
  }
  
  return {
    devicec,
    device,
    touch,
    // 'webgl':glCheck(),
  }
}

export function revCheck (){
  createRev()
  const inp = document.querySelector('.checkfix_c input')
  inp.onclick = () => createGrid(inp)


  
  window.addEventListener('resize',()=>{
    createRev()
    
  })
}

function createRev(){
  if(!document.querySelector('.checkfix')){
    const checkfix = document.createElement('div')
    checkfix.className = 'checkfix'
    checkfix.insertAdjacentHTML('afterbegin','<div class="checkfix_t"></div><div class="checkfix_c"><input type="checkbox" id="grid" /><label for="grid">Show grid</label></div>')
    
    document.querySelector('body').appendChild(checkfix)
  }
    
    
  const w = window.innerWidth
    const h = window.innerHeight

    let zoom = w!= window.outerWidth
    let ratio = ((h*10)/w).toFixed(2)
    let ratiocalc = ((h)/w).toFixed(2)
    document.querySelector('.checkfix_t').innerHTML = 'Width: '+w+'<br>Height: '+h+'<br>Ratio: 16/'+ratio+'<br>Ratio: '+ratiocalc+'<br>Zoom: '+zoom
  
}

function createGrid(inp){
  const w = window.innerWidth

  if(inp.checked){
    let cols = ''
    let ncols = w > 1195 ? 12 : 4
    let wcols = ncols == 12 ? 1 : 4

    for(let i=0;i<ncols;i++){
      cols+='<div class="cl'+wcols+'"></div>'
    }

    document.body.insertAdjacentHTML('beforeend','<div class="CKgrid"><div class="gridcl">'+cols+'</div></div>')
    document.querySelector('.CKgrid').style.display ='block'
    setTimeout(()=>{
      document.querySelector('.CKgrid').classList.add('A')
    },120)
  }
  else{
    document.querySelector('.CKgrid').classList.remove('A')
    inp.classList.add('INA')

    setTimeout(()=>{
      document.querySelector('.CKgrid').remove()
      inp.classList.remove('INA')
    },600)
  }
}


export function glCheck () { 
  try {
   var canvas = document.createElement('canvas')

   if(!!window.WebGL2RenderingContext &&
    (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'))){
      canvas.remove()
      return 'webgl2'
    }
   if(!!window.WebGLRenderingContext &&
    (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'))){
      canvas.remove()
      return 'webgl'
    }
    
  } catch(e) {  
    canvas.remove()
    return false;
  }
}



// export default { browserCheck, revCheck,sizer }
