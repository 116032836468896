import * as lib from '/front/js🧠🧠🧠/🔧utils.js'


export default class{
  constructor (el) {
    this.el = el

    // 💡💡💡 NEEDY
    // Esto es para, que al crear el componente, puedas pedir al creador de CMP
    // que incluya ciertas funciones. Hay un estandar para el lenis que es lenis., 
    // y así puedas recibir el stop y el start
    this.needy = new Set(
      ['SCRFn','initCTA','lenis.'])
    
    //💡Para probar que todo va bien
    // setTimeout(()=>{
    //   // this.SCRFn(0)
    //   // this.initCTA()


    //   this.lenisstop()
    // },3000)
    // setTimeout(()=>{
    //   // this.SCRFn(0)
    //   // this.initCTA()


    //   this.lenisstart()
    // },6000)
  }




  set(OPS){

  }

  async kill(){
    // this.killEVT()
  }


  setEVT(){
   
  }
  killEVT(){
   
  }


  resizeFn(SCLLy = window.scrollY){

  }

}