//🟠🔴🔵🟢🟣🟡⚪⚫🟤




import { IOShome, CMPhome } from '/front/views👁️👁️👁️/⚪Home/itemshome.js'
import { IOSlegal, CMPlegal } from '/front/views👁️👁️👁️/🟣Legal/itemslegal.js'
import { IOSerror, CMPerror } from '/front/views👁️👁️👁️/🔴Error/itemserror.js'



export const LITCLSlist = {
  'home': IOShome,
  'legal': IOSlegal,
}




export const LITlist = {
  'home': CMPhome,
  'legal': CMPlegal,
  'error': CMPerror,
}



// IOS BASE
// EL LAZY hay que hacerlo en el puto IO.js porque el hay que hacerle un bind
//CLASES IO


import tipoupd from '/front/ios⛓️⛓️⛓️/IOtipoupd.js'
import tiposll from '/front/ios⛓️⛓️⛓️/IOtiposll.js'

export let LITCLScommon = {
  // 'lazy':(io)=> LITLazy.bind(this)(io),
  // 'scrx':(el)=>  new ScrX(el),
  // 'par':(el)=>  new Par(el),

  'tipoupd': (el) => new tipoupd(el),
  'tiposll': (el) => new tiposll(el),
  'default': (io) => undefined
}

//ANM IO está en el archivo ioanm
import Foot from '/front/comps🦾🦾🦾/Footer🔥/CMPFOOT.js'
import Testimonials from '/front/comps🦾🦾🦾/Modules/Testimonials/CMPtestimonials.js'


// COMPONENTES BASE
export const LITcommon = {
  'testimonials': (el) => new Testimonials(el),
  'foot': (el) => new Foot(el),
  'default': (el) => undefined

}