
export async function getSkin(url,func='getSkin'){

  return new Promise((resolve) => {

    console.log('la url')
    console.log(url)

  fetch(url, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    },
  })
  .then(async (response) => {
    console.log(response)
    // response.text()
    const data = await response.text()
    
    console.log(data)


    // let parser = new DOMParser()
    // let doc = parser.parseFromString(data.about, 'text/html')
    // console.log(doc)
    resolve(data)
  })
  .catch(error => console.error('Error:', error))

})
}


// export async function getSkin(global,func='getSkin'){

//   return new Promise((resolve) => {

//     // let url = window.location.pathname.replace('.php','')
//   let url = window.location.pathname
  
//   // url = url.replace('/','')
//   url = url.replace('index','/')
//   if(url==='/') {
//     url = '//'
//   }
//   else{
//     url = url.replace(/\/$/, "") 
//   }

//   const urlParams = new URL(window.location.href)

//   // Por si no tienes ganas de crear phps para development:
//   url = urlParams.searchParams.has('uC') ? urlParams.searchParams.get('uC') : url
  
  
//   //PREVIEW PARA SANITY


//   fetch(global.base+'/php/main.php', {
//     method: 'POST',
//     headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//     },
//     body: new URLSearchParams({
//       functionToCall: func,
//       views: JSON.stringify(url),
//       ops: JSON.stringify(global)
//     }),
//   })
//   .then(async (response) => {
//     // response.text()
//     const data = await response.json()
    
//     // let parser = new DOMParser()
//     // let doc = parser.parseFromString(data.about, 'text/html')
//     // console.log(doc)
//     resolve(data)
//   })
//   .catch(error => console.error('Error:', error))

// })
// }


