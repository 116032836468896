import * as lib from '/front/js🧠🧠🧠/🔧utils.js'


export default class Testimonials {
  constructor(el) {
    this.el = el
    this.pos = -1
    this.act = 0
  }


  set(OPS) {
    this.el.querySelectorAll('.hold_imgs .im')[0].classList.add('act')
    this.el.querySelectorAll('.hold_text')[0].classList.add('act')
    this.el.querySelectorAll('.hold_aut_el')[0].classList.add('act')



    this.setEVT(OPS)

  }

  async kill() {
    // this.killEVT()
  }


  setEVT(OPS) {

    const images = this.el.querySelectorAll('.hold_imgs .im')
    const textHold = this.el.querySelectorAll('.hold_info_top')
    const texts = this.el.querySelectorAll('.hold_text')

    const authorsHold = this.el.querySelectorAll('.hold_aut')
    const authors = this.el.querySelectorAll('.hold_aut_el')

    const prev = this.el.querySelector('button.Prev')
    const next = this.el.querySelector('button.Next')

    const remove = () => {
      gsap.set(textHold, { height: texts[this.act].scrollHeight + 'px' })
      gsap.set(authorsHold, { height: authors[this.act].scrollHeight + 'px' })
      images[this.act].classList.remove('act')
      texts[this.act].classList.remove('act')
      authors[this.act].classList.remove('act')
    }


    const add = () => {
      images[this.act].classList.add('act')
      gsap.to(authorsHold, {
        height: authors[this.act].scrollHeight + 'px', duration: .45, ease: 'power2.inOut',
        onComplete: () => {
          authors[this.act].classList.add('act')
          gsap.set(authorsHold, { height: 'auto' })
        }
      })
      gsap.to(textHold, {
        height: texts[this.act].scrollHeight + 'px', duration: .45, ease: 'power2.inOut',
        onComplete: () => {
          texts[this.act].classList.add('act')
          gsap.set(textHold, { height: 'auto' })
        }
      })
    }

    prev.onclick = () => {
      remove()
      this.act = this.act === 0 ? images.length - 1 : this.act - 1
      add()
    }

    next.onclick = () => {
      remove()
      this.act = this.act === images.length - 1 ? 0 : this.act + 1
      add()
    }



  }
  killEVT() {

  }


  resizeFn(SCLLy = window.scrollY, OPS) {


    // this.set(OPS)

  }

}