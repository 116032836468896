
// import {MDLin,MDLout} from '/front/comps🦾🦾🦾/Modal🖼️/MDL'
import { POPin } from './POP.js'
import * as lib from '/front/js🧠🧠🧠/🔧utils.js'

// 💡💡💡
// SET CTAs
export function setCTA() {


  //🦶 POP ( para ir hacia adelante y hacia detrás )
  this.popFn = POPin.bind(this)

  window.addEventListener('popstate', (e) => {
    e.preventDefault()

    const urlcheck = 'http://192.168.68.113:1234/'
    const backurl = new URL(window.location.pathname, urlcheck)

    this.popFn('.' + backurl.pathname, false, true)
  }, { passive: true })


  //MDL
  // this.MDLin = MDLin.bind(this)
  // this.MDLout = MDLout.bind(this)


}



// 💡💡💡
// Búsqueda de CTAs ( links, modales, etc etc)
export function initCTA() {

  //LINKS
  const LINKS = new Set(document.querySelectorAll('a'))

  //🦶 Limpia si hay antiguos que no existen
  const OLDlinks = this.CTAlinks.size > 0 ? lib.SETdifference(this.CTAlinks, LINKS) : undefined
  if (OLDlinks) {

    for (const val of OLDlinks) {
      val.onclick = null
      this.CTAlinks.delete(val)
    }

  }

  // 💡💡💡
  // onclick del link
  const actual = new URL(document.baseURI)
  // console.log(actual)

  for (let link of LINKS) {

    // check si tiene href
    if (!link.href || link.getAttribute('href') == '' || link.target == '_blank') continue

    let lhref = new URL(link.href, document.baseURI)

    // check si tiene la misma base
    const chkrel = actual.origin === lhref.origin

    if (chkrel == false) {
      link.target = '_blank'
      link.href = link.classList.contains('SHA') ? link.href.replace('MYURL', window.location.href) : link.href

      continue
    }

    // check si es la misma URL
    const sameurl = actual.href == lhref.href

    if (sameurl == true) {
      link.classList.add('ALK')
    }
    else {
      link.classList.remove('ALK')
    }


    link.onclick = (e) => {
      e.preventDefault()

      let href = link.getAttribute('href')
      if (href.startsWith('#')) {

        this.lenis.scrollTo(href, { offset: 0, force: true })
        if (href = "#mainContent") {
          // document.querySelector('#mainContent').tabindex = -1
          document.querySelector('#mainContent').focus()
        }
        return false
      }
      this.popFn(href, link, false)

    }

    this.CTAlinks.add(link)

  }


  // UIKIT SIZER
  const SIZERS = new Set(document.querySelectorAll('.SizerM'))

  //🦶 Limpia si hay antiguos que no existen
  const OLDsizers = this.CTAsizers.size > 0 ? lib.SETdifference(this.CTAsizers, SIZERS) : undefined
  if (OLDsizers) {

    for (const val of OLDsizers) {
      val.onsizer = null
      this.CTAsizers.delete(val)
    }

  }

  // 💡💡💡

  for (let a of SIZERS) {

    a.onsizer = () => {

      const S = a.parentNode.querySelector('.SizerS')
      const L = a.parentNode.querySelector('.SizerL')
      const style = window.getComputedStyle(a)

      console.log(a)
      S.textContent = parseFloat(style.getPropertyValue('font-size')).toFixed(2)
      L.textContent = parseFloat(style.getPropertyValue('line-height')).toFixed(2)
    }

    a.onsizer()

    this.CTAsizers.add(a)
  }




  //CPY
  const CPY = document.querySelectorAll('.CPY')

  for (let btn of CPY) {

    btn.onclick = (e) => {
      e.preventDefault()

      if (window.isSecureContext && navigator.clipboard) {
        navigator.clipboard.writeText(window.location.href)
      }
      else {

        const textArea = document.createElement("textarea");
        textArea.value = window.location.href
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        document.execCommand('copy')
        document.body.removeChild(textArea)

      }


      // console.log(navigator)
      // console.log(navigator.clipboard)
      // console.log(window.location.href )
      // navigator.clipboard.writeText(window.location.href )
      // console.log(navigator.clipboard)
    }
  }



  // Tab groups

  const tabGroup = document.querySelectorAll('.tabGroup')

  if (tabGroup) {
    for (let a of tabGroup) {
      console.log(a)
      let pos = -1
      const tabs = a.querySelectorAll('.Atab')
      const clicks = a.querySelectorAll('.Atab_c')
      const holds = a.querySelectorAll('.Atab_h')
      const boxs = a.querySelectorAll('.Atab_b')


      for (let [i, click] of clicks.entries()) {
        click.onclick = async () => {
          if (pos != -1) {
            tabs[pos].classList.remove('A')
            gsap.to(holds[pos], { height: 0, duration: .6, ease: 'power2.inOut' })
            if (pos == i) {
              pos = -1
              return false
            }
          }
          tabs[i].classList.add('A')
          gsap.to(holds[i], {
            height: boxs[i].clientHeight + 'px', duration: .6, ease: 'power2.inOut',
            onComplete: () => {
              holds[i].style.height = 'auto'
            }
          })
          pos = i
        }
      }
    }

  }

}