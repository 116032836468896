

import * as lib from '/front/js🧠🧠🧠/🔧utils.js'


export function setEVT() {
  // 💡💡💡
  // Aquí se añade el resize, visibility y los eventos CUSTOM, que se añaden al EVT
  this.EVT = new Map()


  //🦶 Resize
  this.SCRFn = SCRFn.bind(this)
  this.SCRdebounce =
    lib.debounce(() => this.SCRFn(0), 160)
  window.onresize = this.SCRdebounce

  //🦶 Visiblity
  this.visFn = visFn.bind(this)
  document.addEventListener('visibilitychange', this.visFn)


  //🦶 Custom evts
  this.custom = setCustom.bind(this)
  this.EVT.set('custom', this.custom)
  this.EVT = this.custom()
}

//SCREEN EVENTS
export function SCRFn(chk = 1) {
  // 💡💡💡
  // RESIZE
  // CHK Es para comprobar si viene por eventListener o si es forzado
  // Si es evnListener ? 0 : 0
  // Esto se hace para controlar que en móvil, el EVT no se dispare todo el rato con el scroll, que hace resize
  let
    w = window.innerWidth,
    h = window.innerHeight

  //OLD
  // const touch = /Mobi|Andrdoid|Tablet|iPad|iPhone/.test(navigator.userAgent)||"MacIntel"===navigator.platform&&1<navigator.maxTouchPoints
  const touch = window.matchMedia('(hover:none)').matches
  this.OPS.set('touch', touch)

  // console.log(touch)

  if (touch == 1 && this.SCR && chk == 0) {
    if (this.SCR[0] == window.innerWidth) {
      return false
    }
  }

  this.SCR = [w, h]

  //🐭
  if (this.MOU) {

    this.MOU.SCR[0] = w
    this.MOU.SCR[1] = h

  }
  //🌤️

  if (this.nav && this.nav.resizeFn) {

    this.nav.SCR[0] = w
    this.nav.SCR[1] = h
    this.nav.resizeFn(this.OPS)

  }

  //⛓️
  if (this.IOS) {

    for (let io of this.IOS) {
      if (io.get('CLS') != undefined) {

        if (io.get('CLS').resizeFn) {
          io.get('CLS').resizeFn(window.scrollY, this.OPS)
        }

      }
    }

  }

  //🚧🚧 CMP
  if (this.CMPS) {

    for (let cmp of this.CMPS) {
      if (cmp.get('CLS') != undefined) {
        if (cmp.get('CLS').resizeFn) {
          cmp.get('CLS').resizeFn(window.scrollY, this.OPS)
        }

      }
    }

  }

  if (this.CTAsizers) {

    for (let sizer of this.CTAsizers) {

      sizer.onsizer()
    }

  }

}

//PARAR UPD AL OCULTAR VISTA
// document.addEventListener('visibilitychange',(e) =>{
//   // if (this.isload == 1) { 
//   //   return false 
//   // }
//   // console.log('mano de santo')
//   // if(document.visibilityState=='hidden'){

//   //   this.lenis.stop()
//   //   window.cancelAnimationFrame(this.UPD)

//   // }
//   // else{

//   //   this.lenis.start()
//   //   this.update(performance.now())

//   // }

// })
export function visFn(e) {

  if (document.visibilityState == 'hidden') {

    this.lenis.stop()
    window.cancelAnimationFrame(this.UPD)

  }
  else {

    this.lenis.start()
    this.updFn(performance.now())

  }

}


//CUSTOM EVENTS
export function setCustom() {

  let custom = new Map()

  // Scroll 
  custom.set('SLL', new CustomEvent('SLL', { state: null, scrollto: null, y: 0 }))

  document.addEventListener('SLL', (e) => {

    if (e.scrollto != null) {
      // FORZAR SCROLL TO, ¿ IGNORA ESTADO? 
      this.lenis.scrollTo(e.scrollto, { offset: e.y, force: true })
    }
    else {

      if (e.state == 1) {
        this.lenis.start()
      }
      else {
        this.lenis.stop()
      }

    }

  })

  //🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪
  // dspués de cada uso, borrar estado y scrollto

  // custom.get('SLL').state = -1
  // document.dispatchEvent(custom.get('SLL'))
  custom.get('SLL').state = null
  custom.get('SLL').scrollto = null


  // LENIS

  // Animaciones 
  custom.set('ANM', new CustomEvent('ANM', { el: null, state: null, param: null }))

  document.addEventListener('ANM', (e) => {
    //🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧


  })

  //🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪
  // dspués de cada uso, borrar estado, el y param
  //RELLENAR


  // Callers 
  custom.set('CTA', new CustomEvent('CTA', { els: null, state: null }))


  document.addEventListener('CTA', (e) => {
    //🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧


  })
  //🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪🔪
  // dspués de cada uso, borrar estado, els




  //🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧
  // Hacer un cleaner para los custom events?? para borrar estados y demás.


  return custom
}



// UPDATE FN

export function updFn(time) {

  // this.stats.begin()

  if (this.lenis) this.lenis.raf(time)

  //💡 Si no está scrolleando, manda un false

  for (let [key, io] of this.IOSUPD.entries()) {
    io.get('CLS').updFn(
      this.lenis.isScrolling != false ? this.lenis.animatedScroll : false,
      this.VEL
    )
  }

  gsap.updateRoot(time / 1000)

  // this.stats.end()

  this.UPD = window.requestAnimationFrame(this.updFn)

}
