import * as lib from '/front/js🧠🧠🧠/🔧utils.js'


function Apoit(el,ops){
  const ANM = gsap.timeline({paused:true})

  // BUSCAMOS DOM
  const DOM = el.parentNode
  // VARS
  const ease = 'power2.inOut'

  ANM
  // .from(DOM,{width:0, duration:1,ease},0)
  .to(DOM,{'--sPoit':0, duration:1,ease},0)
  .from(DOM,{'--wPoit':0,'--tPoit':1, duration:.6,ease},.8)

  return ANM
}


function tipofnc(el,ops){

  const checkFn = (entry,act) =>{
    const DOM = entry.target.parentNode
      const span = DOM.querySelector('span')
    const pos = entry.boundingClientRect.y

    if(act == 1){
      if(pos > 0 ){
        // menor que pantalla, entra por abajo
        span.innerHTML = 'entro por abajo'
      }
      else{
       // mayor que pantalla, entra por arriba

       span.innerHTML = 'entro por arriba'
      }
    
    }
    else{

      if(pos > 0 ){
        //menor que pantalla, sale por abajo
       span.innerHTML = 'salgo por abajo'
      }
      else{
        //mayor que pantalla, sale por arriba
        span.innerHTML = 'salgo por arriba'

      }

    }

  }

  return ['check',checkFn]


}


export const LITANMbase = {
  'default':() => undefined,
  'Apoit':(el,ops)=>Apoit(el,ops),
  'tipofnc':(el,ops)=>tipofnc(el,ops),
}