import * as lib from '/front/js🧠🧠🧠/🔧utils.js'


export function loadIMG(elem,nowait = null) {
  
  return new Promise((resolve, reject) => {
    
    if(elem.getAttribute('src')){
      resolve(elem)
      return false
    }


    let img = new Image()
    let url = ''
    if(elem.dataset.src){
      
      url = elem.dataset.src
    }
   


    
    let gif=0
    if(/\.(gif)$/.test(url)){
      gif=1
    }

    elem.onload = () => {
      elem.classList.add('L')
      delete elem.dataset.src
      img.remove()
      resolve(elem)
    }

    elem.onerror = () => {
      resolve(elem)
    }

    img.onload = () =>{
      elem.src = url
    }

    img.onerror = () =>{
      // bloque negro
      elem.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjYGRg/A8AAQoBAj0TXDIAAAAASUVORK5CYII='
      resolve(elem)
    }

    img.src = url
    if(gif==1){
      elem.src = url
      elem.classList.add('L')
    }
    
    
  })


}





export async function setIMG(els = null) {

  const IMGwait = new Set(document.querySelectorAll('img.WT'))
  const IMGsteps = new Set(document.querySelectorAll('img:not(.WT)'))

  //BORRAR LOS QUE YA NO ESTÁN
  const OLDwait = this.IMG.size > 0 ? lib.SETdifference(this.IMG,IMGwait) : undefined
  if(OLDwait){
    
    for (const val of OLDwait) {
      if(document.body.contains(val)) continue
      this.IMG.delete(val)
    }

  }
  const OLDsteps = this.IMG.size > 0 ? lib.SETdifference(this.IMG,IMGsteps) : undefined
  if(OLDsteps){
    
    for (const val of OLDsteps) {
      
      if(document.body.contains(val)) continue
      this.IMG.delete(val)
    }

  }

  // CARGAR IMGS
  const PROMs =[]
  for(let path of IMGwait){
    
    if(!this.IMG.has(path)){

      const PROM = loadIMG(path)
      PROMs.push(PROM)

      

    }
  }


  await Promise.all(PROMs)
  .then((results) => {

    for(let img of results){

      this.IMG.add(img)

    }

  })

  this.stepIMG(IMGsteps)

}



export async function stepIMG(els){

  for(let path of els){

    if(!this.IMG.has(path) && !path.dataset.lazy){

      await loadIMG(path)
      this.IMG.add(path)

    }
    
    
  }

}

/// VIDEO🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️🎞️

function cleanVID(elem){
  elem.oncanplay = null
  elem.onplay = null
  elem.currentTime=0

  let isPlaying = elem.currentTime > 0 && !elem.paused && !elem.ended 
  && elem.readyState > elem.HAVE_CURRENT_DATA


  if(!isPlaying && !elem.dataset.auto){
    elem.pause()
  }
}


export async function loadVID(elem, nowait = false) {

  return new Promise((resolve, reject) => {
    if(elem.dataset.loop){
      elem.loop = false
    }
    else{
      elem.loop = true
    }
    elem.muted = true
    elem.autoplay = true
    elem.setAttribute('webkit-playsinline', 'webkit-playsinline')
    elem.setAttribute('playsinline', 'playsinline')
    elem.onplay = () => {

      elem.isPlaying = true
      
    }
    
    elem.oncanplay = () => {
      if (elem.isPlaying) {
        elem.classList.add('L')
        cleanVID(elem)
        resolve(elem)
      }
    }
    
    elem.src = elem.dataset.src
    delete elem.dataset.src
    
    elem.onerror = () =>{
      resolve(elem)
    }
    
    elem.play()
    
  })

}


export async function setVID(els = null) {
  const VIDwait = new Set(document.querySelectorAll('video.WT'))
  const VIDsteps = new Set(document.querySelectorAll('video:not(.WT)'))
  
  const PROMs =[]

  //🦶BORRAR LOS QUE YA NO ESTÁN
  const OLDwait = this.VID.size > 0 ? lib.SETdifference(this.VID,VIDwait) : undefined
  if(OLDwait){
    
    for (const val of OLDwait) {
      if(document.body.contains(val)) continue
      this.VID.delete(val)
    }

  }
  const OLDsteps = this.VID.size > 0 ? lib.SETdifference(this.VID,VIDsteps) : undefined
  if(OLDsteps){
    
    for (const val of OLDsteps) {
      if(document.body.contains(val)) continue
      this.VID.delete(val)
    }

  }


  //CARGAR VIDs
  for(let path of VIDwait){
    
    if(!this.VID.has(path)){

      const PROM = loadVID(path)
      PROMs.push(PROM)

      

    }
  }


  await Promise.all(PROMs)
  .then((results) => {

    for(let vid of results){

      this.VID.add(vid)

    }

  })

  this.stepVID(VIDsteps)

}

export async function stepVID(els){

  for(let path of els){

    if(!this.VID.has(path) && !path.dataset.lazy){

      await loadVID(path)
      this.VID.add(path)

    }
    
    
  }

  
}