import * as lib from '/front/js🧠🧠🧠/🔧utils.js'


export  function setRVL(ops=null){
// 💡💡💡 setear 
// Crear el ldr

const el = document.createElement('div')
el.classList.add('ldr')


// con el back podemos medir si viene de un EVT popstate back
// ops.back == 0 ? gsap.set(el,{yPercent:-100})  : gsap.set(el,{yPercent:100 }) 

// chutar ldr
document.body.insertAdjacentElement('beforeend',el)

// Vars para anims
const ease = 'Power2.inOut'
let duration = .6

  // ANM de entrada ⬅️
  const ANMstart = gsap.timeline({paused:true})
  
  .fromTo(el,{'--LDRt':'0%'},{
    '--LDRt':'100%',duration:1,ease
  },.1)
  .fromTo(el,{'--LDRb':'0%'},{
    '--LDRb':'100%',duration:1.2,ease
  },.1)

 

// ANM de Salida ➡️
// puedes mod vars de anims
duration = 1

  const ANMend = 
  gsap.timeline(
    {
      paused:true,
      onComplete:()=>{
        el.remove()
      }
  })
  .to(el,{
    '--LDRt':'0%',duration:1,ease
  },1)
  .to(el,{
    '--LDRb':'0%',duration:1.2,ease
  },1)
  .to(el,{
    '--LDRx':'-25%',duration:1,ease
  },.9)

  const RVL = new Map([
    ['el',el],
    ['ANMin',ANMstart],
    ['ANMout',ANMend],
    ['type',ops.type ? ops.type : 1],
  ])

  return RVL
}

export async function initRVL(RVL,resolve){
  // 💡💡💡
  // Lanzaría la  animación de entrada.

  await RVL.get('ANMin').play()

  resolve()
  //  lib.timeout(1200)
  
}


// END LOADER
export async function endLDR(RVL){
  // 💡💡💡
  // END LOADER : animación de salida.
  const el = RVL.get('el')

  RVL.get('ANMout').play()
  // await lib.timeout(900)
  
  return RVL
}