//                                            `/+o/.
//                        .+sso+/:oydyo/:-:+shdys/    `-:.     `-/+o+/`
//                    `/sdh+/::/::ss:`ymdhyso//hmMNyhNNms+ososys+/-:/shms/`
//                 .+hNNy++oo+/.`.--/osyhdmNNMMMMMMMMMNdsssssoso+hhhhsoo+ymdo.
//               -smNy/+ymmmmmNNNNMNMMMMMNNNmmNMMMMMMMMMho:///:--shydNMMNdo-sNs`
//             -hNd+-sNMNdmNMMMNNNMNNNMMMddNMMNNmNMMMMMMNmy+///::/:-:/++ymNNdmMN:
//           `sNMs`+NMNNNMMMMNNNMMMMMMNmhyso///+ohMmoNMmoo+/::/-:oymNNmsosshdhmMM/
//          +NMMy`hMMMhyNMNMMNNNMds:-.`-:syddmNMMmyo`+yMMho:..-+//++omMNNNNNNNmdNMs
//        :mMMMh`yMNdodNNNMNMMMs.+sdmmmmmdhNMMMNhy/..`-syhNmdyssso+/.`:yNMMMMNMNMMMy
//       :NMNh:-+MMh+mdNNNNNMd.+NNMMMMMMMMmho:-......:--::ohNMMMMMMNmNy/.oNMNmNMNMMMs
//      :NMm+/dmmMNydyhNdhMMN.yMMNmhysso+:-``        ```.--:/+sdMMMMMNNNm:-mMNNNNMMMMy
//     :NMy/hNMMMMmNddsh/NmMy-Mms:..`.--.`                ``..-.:yNMMMMNMNs:NMMMNNNNMMy
//    :NNy/mMMMMMMmNMMshsNdMo/d-...``                       ```...-yMMMNNMd`NMMNMdmoNMM-
//   /mMm+NMNNMMNMNNNNNNNNMMmom/                              ```..`+NMMMMh`NMMMMNNdhNMh
//  +NMMmmMNyNMNMMMMMNmmmNMdNNyh+.                             ``````/NMMM::MMMMNMNNmNMN
// +MNNMMMNymMNNMMMNNNNNMh+:+dNmddhyoo+`                        ````.`sMMN`sMNNMNNMNNNNN
// dNNNMNNddMNNNNNNmymMN+---::/shdhyyy:                         `````..hMo.NMNMNMMMNmMMd
// dNNNMMNmNNNmmNMNdNMM+.-..----.-:::.                          ````...:mh/NMMMNMMMNNMMh
// sMNNMMNMNNmyNMNdmNMo--.....                                  ``...---:dNMNMMNMMNNNMMN.
// :NNNMMMNNNsmMNmMNMy...`.-.`                                    `.-----:odNmmNMMMMMNMMo
// .NMMMmMMMNmMNNNNMm:-.```..                                       ``-----:/o//dMMMNMMMm
// .NMMMNMMNMMNMNNNNs--.``...                                          `....---..dMNMMMMM`
// .NNMMNNNNNMMMNNNN:-...`...                                           ```.....`+MMMMMMM.
// .MNNNNNNNMMMMMNNy.......-.`                                          ``..---.`.NMMMMMM`
// `NMNMMNNNMMNMMMm-...`.-----.`                                        ``.-----.`yMMMMMd
//  dMMMNNNNMMNNMMo`-....----..`          ``                      `.`` ```.------`:MMMMM-
//  /MMNMNNNMMNMMN-`.`..-.--.` `--..-:-.-.``..``               ```.-......--.----..NMMMd
//  `mMNMNNMMMNNMN.``...-.-../hddyysyhysyyso+--/::-..--...----:::+syyyyhhdddy+:-.-.hMMM:
//   :NNNNNNMMMMMN.`....--.:dy/:-.-/+++ososss+/:+shyo/::/:+os+:+syosyoso+/://ss//.`+MMm
//    +MdmNNMNMMMN+.--....:+-.-:+ooymdddmdhyo++ss+/yMo.`..oNsyhdhmdmmmmNmdo:-.--:+-:MM/
//   `y/..-+dNNMMMo-shhyo++--+sso-`dsymoso.smyso+//.od+/:/ho+yyhd/ymsNhyy./yy/``.-hhmm`
//   .s+md+- oMMMm``.-/sy//-.+/s.  odys+s-  /shyso+.sm+:::yd/hh+:`.hyyhy- `/y/.` `hs/s`
//   -oyMNyhs:NMMo     `.-`         .---` ``.`/::+s/ms````-mo+:`````.--` ````     `sNm`
//   `hsMh`.hymMM:       `-         `          .:+:hy`     od:-`                  .+sM-``
//    o+o/``-/mMM-        .-                ``.```hy`       s.`.`                 -/+M+``
//   .s `./NMMMM-         --            ````  `:ho`        .s`  ```             ./.+My`
//    /: `+MMdMM/          -.  `       `   ..+++-           :d/.             ``:o-`oMy
//     o. .sdNMMm`            `--:://+//.`-///:.           `.ohooo:-.`` `.-:+//:..`hMy
//     `s```.yMMMs                  ```     .y+  `::.:----.-``o:-::/:::--:::-----..mMo
//      :s` `oMNMN-                         :N+  -NNhy/:/sds./:..----------------`/MN.
//       +o``-NMNMd`                      `-syyoo++/.++:so/+yN+..--....-..-....--`dM+
//        +:.`oMNNN`                     .:-` `.::.` `--..---/+/---.```........-.:d:
//         ./++Ny::`                   `--`          .--..-----::-..```......---.s.
//           `:os.--`                  .`            `.. ``.------.`.```..-----.:o
//             `h-..`                 ``        .:syy/-/ydho-.--...`````.------.+.
//              +o`.`                        ./ymNNNNNNNmmNNNh:....``.```.-----:s
//              `h-`.                    -/+oyo/:----:---.--:+sso:........--::-+:
//               /d...                 `.++:  -:--/+:/oo+o++-.``--.....-----:-:y
//               `Md:.`                ``     `-:/+ooooo+/-........-----------yo
//                mNNs-`                     `..-/oo+://:/oo:......----------os
//                h:+md:.                  ...``.`         `------.---------++
//               `h..-+ddo.`                            ``.----------------s:
//                 h` .--/ydy:`                   `...--------------------+y.
//                 h`   ..--+yds+.`               `....----------------:+dN`
//                `y      `.-.-:sdhs:.`    `...````..----------------:smsdm
//                `h         .--..-+ymdy+/:----:----------------.-/shs+.`os
//                `h           `..--..:sdmmhyo/::----------::/+syhy/....`+-
//                -y              `..--..--/oosyyyhhhyyyssoooo/:.`...`.` /-
//                `.                  `..--.......................````   +`
//                                       `...------..-.........``
//                                           ``..-.--........``
//                                                ```..```


// ⚔️🛡️❗️❕❓❔‼️⁉️🔱⚜️🔰💬💭🗯️
//📚 LIBS
// import '@splidejs/splide/css/core';
// BASE & THEMES STYLES
// import '/front/style🖌️🖌️🖌️/base🔧/tests.scss'


import '/front/style🖌️🖌️🖌️/🖌️🪪themestyles.scss'
// 🧿 ATOMS STYLES
import '/front/atoms🧿🧿🧿/🖌️🧿atomstyles.scss'
// 🦾 CMPS STYLES
import '/front/comps🦾🦾🦾/🖌️🦾cmpstyles.scss'
// ⛓️ IOs STYLES
import '/front/ios⛓️⛓️⛓️/🖌️⛓️iosstyles.scss'
// 👁️ VIEWS STYLES
import '/front/views👁️👁️👁️/🖌️👁️viewstyles.scss'

//🕸️🕸️🕸️ Browser
import { browserCheck, revCheck } from '/front/start🏁🏁🏁/browser🕸️.js'

import { getSkin } from '/front/start🏁🏁🏁/load🧱.js'

// 🐙 MAIN CLASS
import { App } from '/front/js🧠🧠🧠/🐙APP.js'

//📚 LIBS
import gsap from 'gsap'
// import Observer from 'gsap/Observer'
import SplitType from 'split-type'
// import FontFaceObserver from 'fontfaceobserver'
import * as lib from '/front/js🧠🧠🧠/🔧utils.js'


//🏁🏁🏁🏁

if (window.history.scrollRestoration) {
  window.history.scrollRestoration = 'manual'
}

document.documentElement.classList.add('lenis-stopped')

//🕸️ Browser INIT

//🚧🚧🚧 esto está a pelo por url, revisar
let global = browserCheck()

global.base = ''
global.first = 1
if (import.meta.env.DEV == true) {

  document.documentElement.classList.add('dev')
  global.base = window.location.origin.replace('1234', '8888')

}
const url = new URL(window.location.href)

if (url.searchParams.has('design') || import.meta.env.DEV) {
  console.log('design')
  revCheck()
}
if (url.searchParams.has('preview')) {
  console.log('preview')
  global.preview = 1
  global.previewnonce = url.searchParams.has('preview_nonce') ? url.searchParams.get('preview_nonce') : false
  global.previewid = url.searchParams.has('preview_id') ? url.searchParams.get('preview_id') : false
  global.pageid = url.searchParams.has('page_id') ? url.searchParams.get('page_id') : false

}



//📚📚 LIBS INIT

window.gsap = gsap
gsap.ticker.remove(gsap.updateRoot)
window.SplitType = SplitType


//🎬🎬🎬 Check VIDEO
const video = document.querySelector('#videoLow')
const videoprom = new Promise((resolve) => {
  video
    .play()
    .then(() => {
      video.remove()
      global.lowbat = 1
      resolve(true)
    })
    .catch((error) => {
      global.lowbat = 0
      video.remove()
      resolve(false)
    })
})

//🐙🐙🐙🐙 INIT APP

const PROM = await Promise.all([
  videoprom,

  // POR SI HAY QUE HACER PROMISES DE FUENTES
  // new FontFace('mont','url(/public/fonts/montrealbook.woff2)',{
  //   style:'normal',weight:400
  // })


])
  .then((data) => {
    global.lowbat = data[0]
    global.first = 0


    // lib.condev(data[1])

    // const parser = new DOMParser()

    // const loader = parser.parseFromString(data[1]['loader'], 'text/html')
    // document.querySelector('#app').insertAdjacentHTML('beforeend',loader.body.textContent)
    // const nav = parser.parseFromString(data[1]['nav'], 'text/html')
    // document.querySelector('#app').insertAdjacentHTML('beforeend',nav.body.textContent)
    // const skin = parser.parseFromString(data[1]['skin'], 'text/html')
    // document.querySelector('#app').insertAdjacentHTML('beforeend',skin.body.textContent)

    // const Main = new App(global)
    // document.fonts.add(data[0])

  })
new App(global)



