import * as lib from '/front/js🧠🧠🧠/🔧utils.js'


import Nav from '/front/comps🦾🦾🦾/Nav🌤️/NAV.js'
//SCROLL
import { setSLL, initSLL } from './SLL'
//EVENTS
import { setEVT, setCustom, updFn } from './EVT'
//CALLS
import { setCTA, initCTA } from './CTA'
//IO
import {
  setIOS, initIOS, startIOS,
  killIO, viewIO
} from '/front/ios⛓️⛓️⛓️/IO'
//CMP
import {
  setCMPS, initCMPS, startCMPS,
  killCMP
} from '/front/comps🦾🦾🦾/CMP.js'
//LDR
//💡 el "as endPop" es para sacar una fn con otro nombre
import { setRVL, initRVL, endLDR, endLDR as endPop } from '/front/comps🦾🦾🦾/Loader⏳/LDR'
//IMG&VIDEO
import { setIMG, stepIMG, setVID, stepVID } from './🎞️📸LD'
//🥼🥼🥼
// import Stats from 'stats.js'


export class App {
  constructor(data) {
    lib.condev('init APP')
    // this.app = document.querySelector('#app')

    //🦶 Empieza busy ( es para que los clicks y demás no se lancen, un check)
    this.busy = 1

    //🦶 URL inicial
    this.URL = window.location.pathname

    //🦶 Iniciamos opciones en un MAP
    this.OPS = new Map()
    this.OPS.set('base', data.base)
    this.OPS.set('device', data.device)
    this.OPS.set('lowbat', data.lowbat)
    this.OPS.set('touch', data.touch)
    this.OPS.set('first', 0)

    //🥼🥼🥼 esto para testeos de rendimiento, hay que importarlo
    // this.stats = new Stats()
    // this.stats.showPanel( 1 )
    // document.body.appendChild( this.stats.dom )


    //🦶 Creación de la función de Update
    this.updFn = updFn.bind(this)


    //🦶 Iniciamos APP
    this.setApp()


  }




  async setApp() {

    //SCREEN
    this.SCR = [window.innerWidth, window.innerHeight]

    //SCROLL
    this.SLL = new Map()
    this.SLL.set('speed', 0)
    this.SLL.set('target', 0)
    this.setSLL()


    //EVENTS
    this.setEVT()


    //CALLERS
    // aquí ir añadiendo los distintos CTAs,
    //el de links es obligatorio, pero alguna web tendrá para modal, videos, etc
    this.CTAlinks = new Set()
    this.CTAsizers = new Set()
    // this.CTAvid = new Set()
    this.setCTA()


    //IOs
    // Array donde se albergan los IOS ( Array que alberga los MAPS )
    // El SLL solo para los que funcionan con scroll
    // El UPD para los que necesitan upd todo el rato
    //🦶 Prepara Array y SETS
    this.IOS = []
    // Son sets que directamente albergan la clase que vamos a utilizar para la updFn
    this.IOSSLL = new Set()
    this.IOSUPD = new Set()
    //🦶 Prepara sistema IO
    this.setIOS()

    //COMPONENTS
    // Igual que los IOS, pero sin ser observados
    this.CMPS = []
    //🦶 Prepara sistema CMPS, va vacío casi siempre
    this.setCMPS()


    //MATERIALS
    //IMGS
    //VIDEOS
    // 🩻🩻🩻🩻🩻
    // quizás el lazy controlar si está ya cargado? sería que al entrar, si tiene el src, ya se le pone el okFn
    // daría igual que volviera a meter el elemento en el this.IMG o this.VID porque al ser un Set no duplica. 
    // esto sería para controlar los pauses de los videos ( compatible con Wait y todo)
    this.IMG = new Set()
    this.VID = new Set()





    // MOUSE?
    // COOKIES?


    this.nav = new Nav({
      nav: document.querySelector('.nav'),
      head: document.querySelector('.head'),
      ops: this.OPS,
      evt: this.EVT,
    })


    const LDR = setRVL()
    this.updFn()

    await Promise.all([
      this.setIMG(),
      this.setVID(),
      initRVL(LDR)
    ])

    this.initApp(LDR)


  }


  //💡 RVL puede ser LDR o POP
  // depende de si es la primera vez que se lanza la página o transición entre vistas
  //🚧🚧🚧 Darle una vuelta a estos nombres
  async initApp(RVL) {


    // Iniciar los ios y cmps una vez cargadas las imágenes, videos..., 
    // posiblemente se pueda poner un await, por si alguno de los componentes tiene que 
    // hacer alguna carga ( CMPS con blog, por ejemplo, y el IOS por si acaso, tmb )
    await this.initCMPS(document.querySelector('main').dataset.skin ? document.querySelector('main').dataset.skin : undefined)
    await this.initIOS(document.querySelector('main').dataset.skin ? document.querySelector('main').dataset.skin : undefined)


    // Aquí pregunta si el LDR ( que puede ser LDR o POP )
    const type = RVL.type ? RVL.type : this.OPS.get('first')

    // 🦶 Lanzamos CMPS
    const ANM = await this.startCMPS(type)

    //💡 Tiempos que nos determina el cmp intro
    let timein = ANM.has('in') ? ANM.get('in') : 0


    // 🦶 Lanzamos IOS, cada io puede tener un delay por si 
    // Ese timein es por si queremos sumar un delay por algo
    this.startIOS(timein)

    // 🦶 Final de LDR ⏳ se comprueba si es FIRST o no
    // LDR es loader
    // POP es entre vistas
    //🚧🚧🚧 Darle una vuelta a estos nombres
    this.OPS.get('first') == 0 ? await endLDR(RVL) : await endPop(RVL)

    // 🦶 Tiempo de espera hasta que se lanzan los IOS, casi siempre 0
    if (ANM.has('in')) await lib.timeout(timein)

    // 🦶 Lanzamos Animación
    if (ANM.has('ANM')) ANM.get('ANM').play()

    // 🦶 Tiempo de espera final hasta que la vista se pueda iniciar, lo setea el CMP intro
    if (ANM.has('out')) await lib.timeout(ANM.get('out'))

    // 🦶INICIA CTAS
    this.initCTA()
    // 🦶 INICIA SCROLL
    this.initSLL()
    this.lenis.start()

    const actual = new URL(document.baseURI)

    // 🦶 Se le adjudica el valor 1 al first
    this.OPS.set('first', 1)

    document.documentElement.classList.remove('lenis-stopped')

    if (actual.hash && document.querySelector(actual.hash)) {
      //🚨 Sin el timeout, no chutaba bien
      await lib.timeout(300)
      this.lenis.scrollTo(actual.hash, { offset: -200, force: true })
    }
    this.busy = 0
  }





}


//SCROLL 
App.prototype.setSLL = setSLL
App.prototype.initSLL = initSLL

//EVENTS
App.prototype.setEVT = setEVT
// App.prototype.setCustom = setCustom
//IOS
App.prototype.setIOS = setIOS
App.prototype.initIOS = initIOS
App.prototype.startIOS = startIOS
//IO
App.prototype.viewIO = viewIO
App.prototype.killIO = killIO
//CTA
App.prototype.setCTA = setCTA
App.prototype.initCTA = initCTA
//CMP
App.prototype.startCMPS = startCMPS
App.prototype.setCMPS = setCMPS
App.prototype.initCMPS = initCMPS
App.prototype.killCMP = killCMP


//IMG & VIDEO
App.prototype.setIMG = setIMG
App.prototype.stepIMG = stepIMG
App.prototype.setVID = setVID
App.prototype.stepVID = stepVID


