

// import Introio from './0intro/IOerror.js'


export const IOSerror = {

  // 'error':(el)=>  new Introio(el),
}
import Intro from './0intro/errorintro.js'

export const CMPerror = {

  'intro':(el)=>  new Intro(el),

}