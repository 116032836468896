
import Lazy from '/front/ios⛓️⛓️⛓️/lazy/IOlazy'
import { loadIMG,loadVID } from '/front/js🧠🧠🧠/🎞️📸LD'
import {killIO} from '/front/ios⛓️⛓️⛓️/IO'
    
export  function LITLazy(el) {
  
      const cls = new Lazy(el)
      if(cls.type=='VID'){
        //🦶 Añadimos la función de carga de VID
        //🦶 Al terminar, lanza OkFn y lo metemos en el Set de VID
        cls.loadFn = loadVID
        cls.okFn = () => {

          this[cls.type].add(cls.MD)
        
        }

      }
      else{
        //🦶 Añadimos la función de carga de IMG
        //🦶 Al terminar, lanza OkFn y lo metemos en el Set de IMG
        //🦶 SI ES IMG, lo mata.
        cls.loadFn = loadIMG
        cls.okFn = () => {


          this[cls.type].add(cls.MD)
          this.killIO(el)

        }
      }
      
      return cls

}