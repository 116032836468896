import * as lib from '/front/js🧠🧠🧠/🔧utils.js'


export default class Sll{
  constructor (el) {



    this.el = el
    this.parent = this.el.parentNode
    this.upd = 'SLL'

  }
  
  set(OPS){

      
      this.setBND()
      
        const line = this.parent.querySelector('.line')

        
        const ease = 'Power3.out'
        const duration = 1

        this.ANM = gsap.timeline({paused:true})
        .fromTo(line,{scaleX:0},{scaleX:1,duration,ease}, 0)

      

      
    
  }
  kill(){
    // 💡💡💡 Mata todos los procesos del IO
    this.active = 0
    // this.killEVT()
    
  }

  chkFn(entry,pos){
    let CHK = false
    
    if(entry.isIntersecting==undefined){
      
      CHK = undefined
    }
    else if(entry.isIntersecting==true){
      //🦶 IN
      CHK = this.start()

    }
    else{
      //🦶 OUT
      CHK = this.stop()

    }

    return CHK


  }
  
  start(){
    if(this.active == 1){
      return true
    }
    this.active = 1
   
    return true
  }
  
  stop(){
    if(this.active == 0){
      return false
    }
    this.active = 0
   
   
    return false
  }


  updFn(SCLLy = window.scrollY){
    if(this.active == 0 || !this.ANM){
      return false
    }

    //🦶 Aligeramos pasos, y lo que nos interesa en el clamp es lo 2º, el 1º y el 3º son limits
    this.BND.set('CRT',
    lib.clamp(
      0,
      this.BND.get(1),
      (SCLLy + this.SCR[1]) - this.BND.get(0),
    ))
    

    this.BND.set('PRG',
     parseFloat(
      (
        this.BND.get('CRT')  / this.BND.get(1)
      )
    .toFixed(8)))
    

    this.ANM.progress( this.BND.get('PRG') )

 
   }
  setBND(SCLLy = window.scrollY){
    //🦶 Posiciones, tamaños, etc
    // Start 0         End (limit )    1    
    // current CRT     Progress PRG        
    const h = window.innerHeight
    const BND = this.el.getBoundingClientRect()
    const start = parseInt((BND.y + SCLLy))
    const limit =  BND.height

    this.BND = new Map([
      [0,start],
      [1,limit],
      ['CRT',0],
      ['PRG',0]
    ])

    this.SCR = [window.innerWidth,window.innerHeight]

  }
  resizeFn(SCLLy = window.scrollY,OPS){
    // 💡💡💡 


    if(this.ANM){
      
      this.set(OPS)
      this.updFn(SCLLy,0)
      
    }

    
  }
  
}
