import * as lib from '/front/js🧠🧠🧠/🔧utils.js'


import { LITLazy } from '/front/ios⛓️⛓️⛓️/lazy/LIT'
import ScrX from '/front/ios⛓️⛓️⛓️/scrx/IOscrx'
import Par from '/front/ios⛓️⛓️⛓️/IOpar'


// CARGAR LAS ESPECÍFICOS DE VISTA
import {LITCLScommon,LITCLSlist} from '/front/views👁️👁️👁️/⛓️🦾viewcmpios.js'

// CARGAR LOS ANM BASE
import { LITANMbase } from './IOanm.js'
// SET IO
export  function setIOS(){
  // 💡💡💡
  // Crea el observer, la FN que comprueba la entrada de las clases y los mete o no en el update
  

  const root = null
  const opts = {
    root:root,
    threshold:[0]
  }


  //🦶 Llamada callback a IO
  this.IOfn = callIO.bind(this)

  //OBSERVER
  // this.IO.set('ob',new IntersectionObserver(this.IOfn,opts))
  this.IOobs = new IntersectionObserver(this.IOfn,opts)

  //CHECKCLASS
  // 💡💡💡
  //la FN que comprueba la entrada de las clases ylos mete o no en el update
  this.chkCLS = (io,entry) =>{
    const CLS = io.get('CLS')
    let CHK = CLS.chkFn(entry,this.lenis.targetScroll)
    //🦶 Comprueba si tiene upd
    const type = CLS.upd ? CLS.upd : null
      if(type==undefined) return false
        
      //🦶 Lo mete o lo saca de su correspodiente Set
      if(CHK == true ){

        this['IOS'+type+'ln']= this['IOS'+type+'ln'] + 1
        this['IOS'+type].add(io)

      }
      else if(CHK == false){
        this['IOS'+type+'ln'] = Math.max(this['IOS'+type+'ln'] - 1,0)
        this['IOS'+type].delete(io)
      }
  }

}

// INIT IO
export  async function initIOS(skin='home'){
  // 💡💡💡
  //  Búsqueda de nuevos ios


  // 🦶 COGEMOS LOS IOS NUEVOS
  const IOS = new Set(document.querySelectorAll('.IO'))

  // 🦶 BUSCAMOS IOS QUE YA NO EXISTAN, esto creo que hay que modificarlo, y hacer una especie de array find
  // Habría que ver cómo del IOS ( que al final, va no van a ser solo elementos, pueden tener clases, anm? algo más? 


  const OLD = new Set(this.IOS.filter((ionow) => !IOS.has(ionow.get('el'))))
  // lib.condev(OLD)

  if(OLD){
    
    for (const io of OLD) {
      
      //👁️👁️👁️👁️ comprobar funcionamiento, la parte del update
      this.killIO(io.get('el'))
      
      
      
    }

  }


  // CLASES 
  
  // CARGAMOS EL LAZY CON EL BIND
  LITCLScommon['lazy'] = (io)=> LITLazy.bind(this)(io)
  //🦶  PAra ver si hay un LITCLS con el skin
  //🦶 SE  MERGEA EL IO CON EL DEL TEMPLATE
  const LITCLS = LITCLSlist[skin] ? {...LITCLScommon, ...LITCLSlist[skin]} : LITCLScommon


  // ANIMS ( elementos que no necesitan de una clase pero lanzan una función )
  //🦶 SE  MERGEA EL IO CON EL DEL TEMPLATE
  // const LITANM = existsanm ? {...LITANMbase, ...eval('IOSANM'+skin)} : LITANMbase
  const LITANM = LITANMbase


  // 🦶 INICIAMOS CREACiÓN con el LIT creado ( Son los casos de IOS que podemos tener )
  for(let io of IOS){
    // Comprobar si está ya iniciado
    if(this.IOS.findIndex((ionow) => io == ionow.get('el')) == -1){

      const cls = io.dataset.cls ? io.dataset.cls : 'default'
      const anm = io.dataset.anm ? io.dataset.anm : 'default'
        
      const newio = new Map([
        ['el',io],
        ['CLS',LITCLS[cls](io)],
        ['ANM',LITANM[anm](io,this.OPS)],
      ])

      // console.log(newio)
      // this.IOS.add(newio)
      this.IOS.push(newio)

    }

  }

}

// START IOS
export  async function startIOS(timein){
  // 💡💡💡
  // Setea y muestra los IO
  const obstl = gsap.timeline()
  for(let io of this.IOS){
    if (io.has('act')) continue
    if(io.get('CLS') != undefined) io.get('CLS').set(this.OPS)
    // OBSERVE AQUI, solución alternativa para que el primer observe no se lo coma
      // io.get('el').style.display='none'
      const delay = Math.max(.01,parseInt(window.getComputedStyle(io.get('el')).getPropertyValue('transition-delay')))
      
      obstl
      .set(io.get('el'),{'display':'none'},0)
      .add(()=>this.IOobs.observe(io.get('el')),delay-.01)
      .set(io.get('el'),{'display':'block',
        onComplete:()=>{

          io.set('act',1)
        
        }
      },delay+.01)
    

  }
}

// CALLBACK IO
export  function callIO(entries){
  // 💡💡💡
  // Búsqueda con .filter del IO, comprobamos si tiene class, si tiene ANIM
  


  entries.forEach(entry=>{

    if(entry.boundingClientRect.height == 0){
      return false
    }
    // console.log(this.IOS)
    let CHK = false
    const io = this.IOS.filter((io) => entry.target == io.get('el'))
    

    if(!io[0].has('act')){

      return false
    }


    if(io[0].get('CLS')!=undefined){
      // CHK = io[0].get('class').check(entry,this.lenis.targetScroll)
      
      CHK = this.chkCLS(io[0],entry)
      
    }
    // else if(io[0].get('ANM')!=undefined){

    //   this.viewIO(entry,io[0])
    // }
    else{
      this.viewIO(entry,io[0])

    }

  })


}
export  function viewIO(entry,io=undefined){

  if(entry.target.dataset.norep){
    this.killIO(entry.target)
  }

  const ANM = io.has('ANM') ? io.get('ANM') : undefined

  if(entry.isIntersecting){
    //Start view : es la primera vez que entra, y se mantiene
    entry.target.parentNode.classList.add('SV')
    //In view : cada vez que entra
    entry.target.parentNode.classList.add('IV')

    // SI existe ANM, 
      // y no es un array, es tipo anm, y lo reproduces, 
      // y si es un array, tendrá una función, y le mandas el estado 1 ( lanzar )
    ANM ? ( !Array.isArray(ANM) ? ANM.play() : ANM[1](entry,1) ) : null

  }
  else{
    entry.target.parentNode.classList.remove('IV')

    // SI existe ANM, 
      // y no es un array, lo paras, 
      // y si es un array, tendrá una función, y le mandas el estado 0 ( parar o revertir )
      ANM ? ( !Array.isArray(ANM) ? ANM.pause() : ANM[1](entry,0) ) : null

   
  }

}


export  function killIO(el){
  //se puede matar en dos casos
  //  1. Cuando ya es inútil ( insertar una clase st-view, por ejemplo ) o cuando la clase qeuda inutil ( lazyIMG )
  //  2. Cuando inicias el initIOS y el elemento no existe en el DOM ya, se comprueba con el if de abajo
  //Solo se borrará de IOS si el elemento no existe
  // 💡💡💡
  // Deja de observar, lo busca en this.IOS, si existe ( indes != -1 ), y si no existe en el DOM, lo borra de IOS
  this.IOobs.unobserve(el)

  const index = this.IOS.findIndex((ionow) => ionow.get('el') == el)
  
  if(index != -1){
    // console.log(this.IOS)
    
    // console.log(el)
    // console.log(index+' a borrar')
    
    const cls = this.IOS[index].has('CLS') ? this.IOS[index].get('CLS') : undefined
    if(cls) {
      
      cls.kill()
      this.IOSSLL.delete(this.IOS[index])
      this.IOSUPD.delete(this.IOS[index])
    
    }
    //🦶 Solo lo saca del this.IOS si el elemento no existe
    if(!document.body.contains(el)){
      this.IOS.splice(index,1)
    }
    // console.log(this.IOS)
    

  }


}