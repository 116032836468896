import * as lib from '/front/js🧠🧠🧠/🔧utils.js'



import {LITcommon,LITlist} from '/front/views👁️👁️👁️/⛓️🦾viewcmpios.js'

// 💡💡💡
// SET COMPS
export  function setCMPS(){



}


// 💡💡💡
// Búsqueda de CMPS ( links, modales, etc etc)
export async function initCMPS(skin='home'){

  // 🦶 COGEMOS LOS CMPS NUEVOS
  const CMPS = new Set(document.querySelectorAll('[class^="CMP"]'))

  //🦶 Limpia si hay antiguos que no existen
  const OLD = new Set(this.CMPS.filter((cmpnow) => !CMPS.has(cmpnow.get('el'))))
  if(OLD){
    for (const cmp of OLD) {

      this.killCMP(cmp.get('el'),1)
    
    }
    await lib.timeout(100)
  }
  
  // 🦶 Componentes comunes ( elementos que se usan en varias vistas )
 
  // const LITcommon =  {

  //   'default':(el) => undefined

  // }


  //🦶 SE  MERGEA EL IO CON EL DEL TEMPLATE
  const LIT = LITlist[skin] ? {...LITcommon, ...LITlist[skin]} : LITcommon

  for(let cmp of CMPS){

    if(this.CMPS.findIndex((cmpnow) => cmp == cmpnow.get('el')) == -1){

      const temp = cmp.dataset.cmp ? cmp.dataset.cmp : 'default'


      const newcmp = new Map([
        ['id',temp],
        ['el',cmp],
        ['CLS',LIT[temp](cmp)],
        ['ANM',undefined]
      ])

      // 💬🚧 Aquí se podría poner un .load con await, pero lo ideal es que toda carga inicial se haga
      // al principio del load, no componente a componente
      this.CMPS.push(newcmp)
    }
    

  }

}

// La variable type es para diferenciar si, empieza la página, o es entre vista y vista, se lanza en el 🐙APP.js
// Se le pueden añadir variaciones
  // si es entrando primera vez en la web ? 0 : si es un pop normal es 1, pero a través de endPop podemos chutarle distintas
  
export function startCMPS(type){
  // 💡💡💡
  // Lanza los CMP, y si hay uno que se llama intro
  let intro = undefined
  
  for(let cmp of this.CMPS){


    // cmp.get('el').style.display = 'block'

    if(cmp.has('CLS')) {
      const cls = cmp.get('CLS')
      cls.set(this.OPS)
      //💬 Intro tiene que ser palabra única, para poder esperarlo al entrar
      if(cmp.get('id')=='intro'){

        intro = cls.start(type)
        intro.set('CMP',cmp)
      
      }

      if(cls.needy){

          for(const need of cls.needy){

            !need.includes(".") ?
              cls[need] =()=> this[need]()
              :
                ( need == 'lenis.' ?
                [
                  cls['lenisstart'] =()=> this.lenis.start(),
                  cls['lenisstop'] =()=> this.lenis.stop()
                ]
                : null
                )
          }

      }
      

      if(cmp.get('CLS').leaderFn){

        // cmp.get('CLS').resetFn.bind([this.setCTA(),this.setIOS(),this.setCMPS()])

        // INIT SYSTEM
        cmp.get('CLS').initLeader = async() =>{
          this.lenis.stop()




          await Promise.all([
            this.setIMG(),
            this.setVID(),
          ])
          this.setIOS()
          await this.initIOS(document.querySelector('main').dataset.skin)

          this.startIOS()
          this.initCTA()

        }

        
        
        // END SYSTEM
        cmp.get('CLS').endLeader = async() =>{
          
          
          this.lenis.start()
        }
      }

    }



  }

  if(intro == undefined){

    

      const anm = gsap.timeline({paused:true})
      .fromTo(document.querySelector('main'),{opacity:0},{opacity:1},0)

      intro =  new Map([
        ['in',0],
        ['out',0],
        ['ANM',anm],
        ])
  }
  return intro
}

export  async function killCMP(el,wait = 0){
  const index = this.CMPS.findIndex((cmpnow) => cmpnow.get('el') == el)
  const cls = this.CMPS[index].has('CLS') ? this.CMPS[index].get('CLS') : undefined
  
  if(cls) {
    if(wait == 0){
      cls.kill(wait)
  
    }
    else{
      await cls.kill(wait)
  
    }  
  
  }

  this.CMPS.splice(index,1)
}