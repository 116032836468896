

import * as lib from '/front/js🧠🧠🧠/🔧utils.js'
import Lenis from 'lenis'


// WHEEL 
export function SLLWheel() {
  this.VEL = this.lenis.velocity
  
  
  for(let [key,io] of this.IOSSLL.entries()){

    io.get('CLS').updFn(this.lenis.animatedScroll)

  }
  
  this.SLLthrottle()
  this.SLLdebounce()

  if(this.SLLbar && this.busy == 0) this.SLLbar.progress(this.lenis.progress)
  this.SLL.set('target',this.lenis.animatedScroll)


}



// TOUCH
export function SLLTouch() {

  for(let [key,io] of this.IOSSLL.entries()){
   
    io.get('CLS').updFn( this.lenis.animatedScroll)

  }

  this.SLLthrottle()
  this.SLLdebounce()

}

// THROTLE SCROLL
export function onCheckSLL(){
  
  // lib.condev('throttle')
    //🦶 Comprobamos posiciones de scroll cada x ms mientras hay sll, esto se añade en la función setSLL
  
  if (this.SLL.get('target') > this.lenis.targetScroll) {
    document.documentElement.classList.add('sll-up')
  } 
  else if (this.SLL.get('target') < this.lenis.targetScroll) {
    document.documentElement.classList.remove('sll-up')
  }

  if (this.lenis.targetScroll == 0) {
    document.documentElement.classList.remove('sll-start')
  }
  else if (this.lenis.targetScroll > 0) {
    document.documentElement.classList.add('sll-start')
  }


  // PHIDE
  // this.lenis.isScrolling ? this.pHide.style.pointerEvents = 'all' : this.pHide.style.pointerEvents = 'none'

}


// DEBOUNCE SCROLL
export function onBounceSLL(){

  //🦶 Comprobamos posicion al parar scroll a los x ms, esto se añade en la función setSLL
  // lib.condev('debounce')
  // PHIDE
  // this.lenis.isScrolling ? this.pHide.style.pointerEvents = 'all' : this.pHide.style.pointerEvents = 'none'
}

// SET SCROLL
export function setSLL(){
  const touch = this.OPS.get('touch')

  //Lenis
  this.lenis = new Lenis({
    wheelEventsTarget: document.documentElement,
    lerp: 0.12,
    // duration:.6,
    smoothWheel: !touch,
    smoothTouch: false,
    normalizeWheel: true,

  })
  this.lenis.stop()

  if(touch == 0){
    this.SLLbar = setSLLbar()
  }

}

export function setSLLbar(){

  const scrollBar = document.createElement('div')
  scrollBar.className = 'sllbar'
  scrollBar.insertAdjacentHTML('afterbegin','<div class="sllbar_prg"></div>')
  
  document.querySelector('body').appendChild(scrollBar)

  const SLLbar = gsap.timeline({paused:true})
  .fromTo('.sllbar_prg',{scaleX:0},{scaleX:1,duration:1,ease:'none'},0)


  return SLLbar
}


// INIT SCROLL
export function initSLL(){


  this.onCheckSLL = onCheckSLL.bind(this)
  this.onBounceSLL = onBounceSLL.bind(this)

  //THROTTLE
  // 💡💡💡 Throttle es una fn que lo que hace es 
  // ejecutarse cada periodo de tiempo mientras se está llamando a una Fn sin parar
  this.SLLthrottle = 
  lib.throttle(this.onCheckSLL,500)
  //DEBOUNCE
  // 💡💡💡 Debounce es una fn que lo que hace es 
  // ejecutarse cuando una función se para pasados x MS
  this.SLLdebounce =
  lib.debounce(this.onBounceSLL,30)
  

  //🦶 Dependiendo de si es touch, llamamos a SLLtouch o Sllwheel
  // La diferencia entre estas es la barra de progreso y el mousewheel...
  //🚧🚧🚧 quizás se pueda unificar
  if(this.OPS.get('touch')==true){

    this.scrollFn = SLLTouch.bind(this)

  }
  else{

    this.scrollFn = SLLWheel.bind(this)

  }


    //🦶 Añadimos la fn al evt de scroll del lenis.
    this.lenis.on('scroll',this.scrollFn )

}

// STATE SCROLL

export function stateSLL(){

}