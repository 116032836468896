import * as lib from '/front/js🧠🧠🧠/🔧utils.js'


export default class Intro{
  constructor (el) {
    this.el = el

  }




  set(OPS){

  }

  async kill(){
    // this.killEVT()
  }

  start(pop=0){
    // El pop es para diferenciar si, empieza la página, o es entre vista y vista. Se puede ignorar como en este caso
    // Se le pueden añadir variaciones
      // si es entrando primera vez en la web ? 0 : si es un pop normal es 1, pero a través de endPop podemos chutarle distintas
    const ANM = gsap.timeline({paused:true})
    .from(this.el,{opacity:0,duration:.8,ease:'Power1.inOut',delay:1},0)

    return new Map([
      ['in',0],
      ['out',0],
      ['ANM',ANM],
      ])
  }
  

  setEVT(){
   
  }
  killEVT(){
   
  }


  resizeFn(SCLLy = window.scrollY){

  }

}