import * as lib from '/front/js🧠🧠🧠/🔧utils.js'


export default class Sll{
  constructor (el) {



    this.el = el
    this.upd = 'UPD'
    this.VEL = 0

  }
  
  set(OPS){
    this.parent = this.el.parentNode
    
    if(this.parent.classList.contains('END')){
      return false
    }
      // STEXTs
      this.st = []
      let ts = this.parent.querySelectorAll('.bg .sT')

      let ios = 1
      if(document.documentElement.classList.contains('N-A')){
        ios = 0
      }

      this.setBND()
      
      if(!this.ANMin){
        if(this.ANMin == 0) return false
        const bg = this.parent.querySelector('.bg')
        const scr = this.parent.querySelector('.Actr')
        this.ANMin = gsap.timeline({paused:true,onComplete:()=>this.ANMin = 0})
        
        // .fromTo(bg,{opacity:1},{opacity:.1,duration:1,ease:'power2.inOut'},2)
        // .fromTo(this,{VEL:1},{VEL:.2,duration:1.2,ease:'none'},2)
      
        .fromTo(bg,{opacity:1},{opacity:0,duration:1.6,ease:'power2.inOut'},2)
        .fromTo(this,{VEL:ios},{VEL:0,duration:1.2,ease:'none'},2)
        
        .fromTo(scr,{opacity:0},{opacity:1,duration:.6,ease:'power2.inOut'},2)
      }

      
      // if(!this.tOb){
      //   this.t = this.parent.querySelector('.hj')
      //   this.spt = new window.SplitType(this.t,{types:'lines'})

      //   this.sptFn = () => this.spt.split()
      //     // this.sptFn = this.spt.split()
      //     this.sptdebounce = lib.debounce(this.sptFn,600)
      //     // this.tet = lib.debounce(console.log('chucah'),600)
      //   this.tOb = new ResizeObserver(
      //     (([entry]) => {
      //       // Note: you should add additional logic so the `split` method is only
      //       // called when the **width** of the container element has changed.
      //       // this.spt.split()
      //       this.sptdebounce()
      //       // this.tet()
      //     })
      //   )
      //   this.tOb.observe(this.t.parentNode)
      // }

      this.ANM =   gsap.timeline({paused:true,repeat:-1})
      for(let [i,el] of ts.entries()){
        if(i % 2 == 0){
          this.ANM.fromTo(el.querySelector('.stR'),{x:0},{x:-100+'%',ease:'none',duration:50},0)
        }
        else {
          this.ANM.fromTo(el.querySelector('.stR'),{x:-100+'%'},{x:0+'%',ease:'none',duration:50},0)

        }
      }
      
    this.setEVT(OPS)
    
  }
  kill(){
    // 💡💡💡 Mata todos los procesos del IO
    this.active = 0
    // this.killEVT()
    
  }

  chkFn(entry,pos){
    let CHK = false
    
    if(entry.isIntersecting==undefined){
      //🦶 aquí incluso se puede hacer algo por si se esconde un IO ( para eso el height 0)
      
      CHK = undefined
    }
    else if(entry.isIntersecting==true){
      //🦶 IN
      CHK = this.start(entry)

    }
    else{
      //🦶 OUT
      CHK = this.stop(entry)

    }

    return CHK


  }
  
  start(){
    if(this.active == 1){
      return true
    }

    if(this.ANMin != 0) this.ANMin.play() 
    
    this.active = 1
   
    this.ANM.play()
    return true
  }
  
  stop(entry){
    // 💡💡💡
    // Por si el lerp no ha terminado, la irse de pantalla
    let PRG = 0
    let CRT = 0
    if(this.active == 0){
      return false
    }
    this.active = 0
   
    this.ANM.pause()
    return false
  }

  setEVT(OPS){


    this.killEVT(OPS)
    

  }

  killEVT(OPS){

  }

  updFn(SCLLy = window.scrollY,VEL = 0){


    if(this.active != 1 || !this.ANM){
      
      return false
    }
    

  //LERP DE VEL
    
  //   this.BND.set('CRT',
  //   lib.clamp(
  //     0,
  //     this.BND.get(1),
  //     (SCLLy + this.SCR[1]) - this.BND.get(0),
  //   ))
    
  //   this.BND.set('PRGt',
  //    (
      
  //       this.BND.get('CRT')  / this.BND.get(1)
      
  //   ))
    if(!this.ANMin){


    //   this.BND.set('PRG',
    //   lib.lerp(
    //     this.BND.get('PRG') , 
    //     VEL * .7 , 
    //     // .4
    //     .02
    //   )

    // )
    // this.BND.set('PRG',.2)

    }
    else{
      this.BND.set('PRG',this.VEL)
    }

   
  // this.ANM.timeScale( 1 + Math.abs(this.BND.get('PRG')) )

  this.ANM.timeScale( Math.abs(this.BND.get('PRG')) )
  }

  setBND(SCLLy = window.scrollY){
    
    this.SCR = [window.innerWidth,window.innerHeight]
    const BND = this.el.getBoundingClientRect()
    const start = parseInt((SCLLy + BND.y))
    const limit =  BND.height
    let oldprog = 0
    let oldprogt = 0

    if(this.BND){
      oldprog = this.BND.get('PRG')
      oldprogt = this.BND.get('PRGt')
    }
    this.BND = new Map([
      [0,start],
      [1,limit],
      ['CRT',0],
      ['PRG',oldprog],
      ['PRGt',oldprogt]
    ])


    //STEXTs

    let ts = this.parent.querySelectorAll('.bg .sT')
    
      for(let [i,el] of ts.entries()){

        while(el.querySelector('.stR').clientWidth < this.SCR[0]){
          el.querySelector('.stR').appendChild(el.querySelector('.stS').cloneNode(true));
        }
        el.querySelector('.stF').innerHTML = el.querySelector('.stR').innerHTML
      
      }

  }

  resizeFn(SCLLy = window.scrollY,OPS){
    // 💡💡💡 


    if(this.ANM){
      
      this.set(OPS)
      this.updFn(SCLLy,0)
      
    }

    
  }
  
}
