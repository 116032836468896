import * as lib from '/front/js🧠🧠🧠/🔧utils.js'


export default class Foot {
  constructor(el) {
    this.el = el
    this.pos = -1
  }


  set(OPS) {


    // const form = this.el.querySelector('form')

    // form.classList.add('ERR')
    // const fields = form.querySelectorAll('input')
    // for (let fld of fields) {

    //   fld.oninput = () => this.checkField(fld)
    // }

    // form.onsubmit = async (e) => {
    //   e.preventDefault()

    //   let formData = new FormData()

    //   // const info = {
    //   //   email:fields[0].value,
    //   // }

    //   formData.set("email", fields[0].value)
    //   // console.log(info)

    //   fetch(OPS.get('base') + '/php/funcs/mailchimp.php', {
    //     method: 'POST',
    //     body: formData
    //   })
    //     .then((res) => {
    //       return res.json()
    //     })
    //     .then((data) => {
    //       console.log(data)

    //       form.classList.add('END')
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //       form.classList.add('END')
    //     })
    // }

    this.setEVT(OPS)

  }

  async kill() {
    // this.killEVT()
  }


  setEVT(OPS) {

    const tabs = this.el.querySelectorAll('.Tab')
    const clicks = this.el.querySelectorAll('.Tab_c')
    const holds = this.el.querySelectorAll('.Tab_h')
    const boxs = this.el.querySelectorAll('.Tab_b')


    for (let [i, click] of clicks.entries()) {
      click.onclick = async () => {
        if (this.pos != -1) {
          tabs[this.pos].classList.remove('A')
          gsap.to(holds[this.pos], { height: 0, duration: .6, ease: 'power2.inOut' })
          if (this.pos == i) {
            this.pos = -1
            return false
          }
        }
        tabs[i].classList.add('A')
        gsap.to(holds[i], {
          height: boxs[i].clientHeight + 'px', duration: .6, ease: 'power2.inOut',
          onComplete: () => {
            holds[i].style.height = 'auto'
          }
        })
        this.pos = i
      }
    }

  }
  killEVT() {

  }

  checkField(npt) {
    let check = npt.value == '' ? false : true
    if (npt.type == 'email') check = lib.checkMail(npt.value)


    if (check == false) {
      npt.parentNode.classList.add('ERR')
      npt.parentNode.classList.remove('OK')


    }
    else {
      npt.parentNode.classList.remove('ERR')
      npt.parentNode.classList.add('OK')



    }




  }
  resizeFn(SCLLy = window.scrollY, OPS) {

    for (let click of this.el.querySelectorAll('.Tab')) {
      click.classList.remove('A')
      click.querySelector('.Tab_h').removeAttribute('style')
    }
    this.setEVT(OPS)

  }

}

// gsap.to(this.DOM.postholder, {height: newpost.clientHeight+'px',duration:.9,ease:'power2.inOut'})
//         await gsap.to(newpost, {opacity:1,duration:.9,ease:'power2.inOut', 
//           onStart:()=>{
//             setTimeout(() => {
//               this.events.scrollto.detail.id = this.DOM.postholder
//               this.events.scrollto.detail.force = true
//               document.dispatchEvent(this.events.scrollto)
//             }, 450);
//           },
//           onComplete:()=>{

//             this.DOM.postholder.style.height = 'auto'
//           }}
//         )