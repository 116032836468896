import * as lib from '/front/js🧠🧠🧠/🔧utils.js'


export default class Nav {
  constructor({ nav, head, ops, evt }) {

    this.EVT = evt
    this.type = -1
    this.SCR = [window.innerWidth, window.innerHeight]
    this.isOpen = 0
    this.actOpt = ''
    this.actSubmenu = ''

    this.set(ops, [nav, head])



  }

  set(ops, main = undefined) {
    this.touch = ops.has('touch') ? ops.get('touch') : false
    let type = 0
    // Tipo 0 : Mouse && W > 1194
    // Tipo 1 : Touch && W > 1194
    // Tipo 2 : Mouse && W <= 1194
    // Tipo 3 : Touch && W <= 1194


    if (this.SCR[0] <= 1194) {
      type = this.touch == true ? 3 : 2
    }
    else {
      type = this.touch == true ? 1 : 0
    }



    // CAMBIOS OBLIGATORIOS DE TAMAÑO 

    if (type !== this.type) {
      this.type = type
    }
    else {
      return false
    }


    const nav = main != undefined ? main[0] : document.querySelector('.nav')
    const head = undefined ? main[1] : document.querySelector('.head')
    const brg = head.querySelector('.head_brg')
    const navOps = nav.querySelectorAll('.nav_ops_el:not(.noHov')

    const DOM = new Map([
      ['nav', nav],
      ['head', head],
      ['brg', brg],
      ['navOps', navOps]
    ])


    // CAMBIOS OBLIGATORIOS DE EVT
    // EL KILL LO APLICAMOS EN EL SETEVT
    // this.killEVT(ops, DOM)
    this.setEVT(ops, DOM)
  }


  init() {
    // 💡 Iniciar NAV

  }


  setEVT(ops, DOM) {
    // 💡 Iniciar EVT NAV


    //OPEN CLS
    // NAV
    const nav = DOM.get('nav')
    const navOps = DOM.get('navOps')
    // HEAD
    const head = DOM.get('head')
    const brg = DOM.get('brg')


    if (brg && !this.ANMbrg) {
      this.ANMbrg =
        gsap.timeline({
          paused: true,
          onComplete: () => {

          }
        })
          .fromTo(brg.querySelector('svg'), { opacity: 1, scale: 1 }, { opacity: 0, scale: .6, duration: .3, ease: 'power3.inOut' }, 0)
          .fromTo(brg.querySelector('.Anav_x'), { "--brgscale": 0 }, { "--brgscale": 1, duration: .3, ease: 'power3.inOut' }, .3)

      brg.onclick = async () => {
        if (this.isOpen == -1) return false
        const PRGburger = this.ANMbrg ? this.ANMbrg.progress() : -1
        if (PRGburger != 0 && PRGburger != 1) {
          // PAUSA
          this.ANMbrg.pause()
        }

        if (this.isOpen == 0) {
          this.openFn(nav)
        }
        else {
          this.ANMbrg.reverse()
          this.removeFn()
          this.closeFn(nav)
        }
      }
    }

    if (navOps) {

      const navOpsHold = nav.querySelector('.nav_ops')
      const navMenuEls = nav.querySelectorAll('.nav_menu_el:not(.sub)')
      const menuElsSub = nav.querySelectorAll('.nav_menu_el.sub')


      if (this.type === 0) {
        for (let [i, opt] of navOps.entries()) {
          let optArrow = opt.querySelector('button')
          opt.onclick = undefined
          opt.onmouseenter = () => {
            if (opt.dataset && opt.dataset.menu) {
              if (this.isOpen === 0) this.openFn(nav)
              if (this.actOpt !== opt.dataset.menu && this.actOpt !== '') {
                this.removeFn()
              }
              this.actOpt = opt.dataset.menu
              this.addFn()
            } else {
              this.removeFn()
              this.closeFn(nav)
            }
          }

          optArrow.onclick = (ev) => {
            if (opt.dataset && opt.dataset.menu) {
              if (this.isOpen === 0) {
                this.openFn(nav)
              } else {
                this.removeFn()
                this.closeFn(nav)
              }
              if (this.actOpt !== opt.dataset.menu && this.actOpt !== '') {
                this.removeFn()
              }
              this.actOpt = opt.dataset.menu
              this.addFn()


              // Activar el focus trap para el submenú
              const tabTrap = document.querySelector(`#${this.actOpt}`);
              if (tabTrap) {
                this.activateFocusTrap(tabTrap, optArrow);
              }
            } else {
              this.removeFn()
              this.closeFn(nav)
            }
          }

        }


        // ANTIGUOS EVENTOS FOCUS PARA ACCESIBILIDAD EN EL NAV OPS
        // for (let menu of navMenuEls) {
        //   for (let elem of menu.querySelectorAll('.hold .opt')) {
        //     elem.onfocus = (ev) => {
        //       if (menu.id) {
        //         if (this.isOpen === 0) this.openFn(nav)
        //         if (this.actOpt !== menu.id && this.actOpt !== '') {
        //           this.removeFn()
        //         }
        //         this.actOpt = menu.id
        //         this.addFn()
        //         elem.addEventListener('focusout', (ev) => {
        //           this.removeFn()
        //           this.closeFn(nav)
        //           elem.removeEventListener('focusout', () => console.log('remove'))
        //         })
        //       }
        //     }
        //   }
        // }

        nav.onmouseleave = () => {
          this.removeFn()
          this.closeFn(nav)
        }
        for (let subMenu of menuElsSub) {
          for (let b of subMenu.querySelectorAll('button.opt')) {

            if (b.dataset && b.dataset.submenu) {
              b.onclick = undefined
              b.onmouseenter = () => {
                if (this.actSubmenu !== b.dataset.submenu && this.actSubmenu !== '') {
                  subMenu.querySelector(`.opt[data-submenu="${this.actSubmenu}"]`).classList.remove('act')
                  subMenu.querySelector(`#${this.actSubmenu}`).classList.remove('act')
                }
                this.actSubmenu = b.dataset.submenu
                b.classList.add('act')
                subMenu.querySelector(`#${this.actSubmenu}`).classList.add('act')
              }

              b.onclick = () => {
                if (this.actSubmenu !== b.dataset.submenu && this.actSubmenu !== '') {
                  subMenu.querySelector(`.opt[data-submenu="${this.actSubmenu}"]`).classList.remove('act')
                  subMenu.querySelector(`#${this.actSubmenu}`).classList.remove('act')
                }
                this.actSubmenu = b.dataset.submenu
                b.classList.add('act')
                subMenu.querySelector(`#${this.actSubmenu}`).classList.add('act')
                // Activar el focus trap para el submenú
                const tabTrap = subMenu.querySelector(`#${this.actSubmenu}`)
                if (tabTrap) {
                  this.activateFocusTrap(tabTrap, b, true)
                }
              }
            }

          }

          // ANTIGUOS EVENTOS FOCUS PARA ACCESIBILIDAD EN EL NAV OPS SUB MENU
          // for (let el of subMenu.querySelectorAll('.sub_menu .hold a')) {
          //   el.onfocus = (ev) => {
          //     console.log(ev)
          //     if (this.isOpen === 0) this.openFn(nav)
          //     if (this.actOpt !== subMenu.id && this.actOpt !== '') {
          //       this.removeFn()
          //     }
          //     el.classList.add('act')
          //     el.parentElement.parentElement.classList.add('act')
          //     this.actSubmenu = el.parentElement.parentElement.id
          //     subMenu.querySelector(`.opt[data-submenu="${this.actSubmenu}"]`).classList.add('act')
          //     this.actOpt = subMenu.id
          //     this.addFn()
          //     el.addEventListener('focusout', (ev) => {
          //       el.classList.remove('act')
          //       el.parentElement.parentElement.classList.remove('act')
          //       subMenu.querySelector(`.opt[data-submenu="${this.actSubmenu}"]`).classList.remove('act')
          //       // this.removeFn()
          //       // this.closeFn(nav)
          //       el.removeEventListener('focusout', () => console.log('remove'))
          //     })
          //   }
          // }

        }

      } else {
        if (this.type === 1) {
          nav.onmouseleave = () => {
            this.removeFn()
            this.closeFn(nav)
          }
        } else {
          nav.onmouseleave = undefined
        }

        for (let [i, opt] of navOps.entries()) {
          opt.onmouseenter = undefined
          opt.onclick = () => {

            if (this.type === 1 && this.isOpen === 0) this.openFn(nav)
            if (this.type === 1 && this.actOpt !== opt.dataset.menu && this.actOpt !== '') {
              this.removeFn()
            }
            if (opt.dataset && opt.dataset.menu) {
              this.actOpt = opt.dataset.menu
              this.addFn()
            } else {
              this.removeFn()
              this.closeFn(nav)
            }
          }

          for (let back of nav.querySelectorAll('.top_back')) {
            back.onclick = (ev) => {
              ev.preventDefault()
              navOpsHold.classList.remove('hide')
              nav.querySelector(`#${this.actOpt}`).classList.remove('act')
              this.actOpt = ''
            }
          }
        }

        for (let menu of navMenuEls) {
          for (let elem of menu.querySelectorAll('.hold .opt')) {
            elem.onfocus = undefined
          }
        }

        for (let subMenu of menuElsSub) {
          for (let el of subMenu.querySelectorAll('.sub_menu .hold a')) {
            el.onfocus = undefined
          }
          for (let back of subMenu.querySelectorAll('button.subtop_back')) {
            back.onclick = (ev) => {
              ev.preventDefault()
              subMenu.querySelector(`#${this.actSubmenu}`).classList.remove('act')
              subMenu.querySelector('.sub_main').classList.remove('hide')
              this.actSubmenu = ''
            }
          }

          for (let b of subMenu.querySelectorAll('button.opt')) {
            if (b.dataset && b.dataset.submenu) {
              b.onmouseenter = undefined
              b.onclick = () => {
                if (this.type === 1 && this.actSubmenu !== b.dataset.submenu && this.actSubmenu !== '') {
                  subMenu.querySelector(`button.opt[data-submenu="${this.actSubmenu}"]`).classList.remove('act')
                  subMenu.querySelector(`#${this.actSubmenu}`).classList.remove('act')
                }
                this.actSubmenu = b.dataset.submenu
                subMenu.querySelector('.sub_main').classList.add('hide')
                subMenu.querySelector(`#${this.actSubmenu}`).classList.add('act')
              }
            }
          }
        }
      }





      this.removeFn = () => {
        if (this.actOpt !== '') {
          nav.querySelector(`.nav_ops_el[data-menu="${this.actOpt}"]`).classList.remove('act')
          nav.querySelector(`#${this.actOpt}`).classList.remove('act')
          nav.querySelector(`.nav_media img[data-menu="${this.actOpt}"]`).classList.remove('act')
        }
        for (let el of nav.querySelectorAll('.nav_menu_el.sub button, .nav_menu_el.sub .sub_menu_el')) {
          el.classList.remove('act')
        }
        for (let subMenu of menuElsSub) {
          subMenu.querySelector('.sub_main').classList.remove('hide')
        }
        navOpsHold.classList.remove('hide')
      }

      this.addFn = () => {
        nav.querySelector(`.nav_ops_el[data-menu="${this.actOpt}"]`).classList.add('act')
        nav.querySelector(`#${this.actOpt}`).classList.add('act')
        nav.querySelector(`.nav_media img[data-menu="${this.actOpt}"]`).classList.add('act')

        navOpsHold.classList.add('hide')
      }



      this.activateFocusTrap = (tabTrap, lastFocus, submenu = false) => {
        // const focusableElements = tabTrap.querySelectorAll('a, button, input, textarea, select, [tabindex]:not([tabindex="-1"])')
        const focusableElements = submenu ? tabTrap.querySelectorAll('.subopt') : tabTrap.querySelectorAll('.opt')
        const firstElement = focusableElements[0]
        const lastElement = focusableElements[focusableElements.length - 1]

        const handleKeyDown = (event) => {
          if (event.key === 'Tab') {
            if (event.shiftKey) {
              // Shift + Tab
              if (document.activeElement === firstElement) {
                event.preventDefault()
                lastElement.focus()
              }
            } else {
              // Tab
              if (document.activeElement === lastElement) {
                event.preventDefault()
                firstElement.focus()
              }
            }
          } else if (event.key === 'Escape') {
            // Escape
            tabTrap.removeEventListener('keydown', handleKeyDown)
            tabTrap.removeEventListener('focusout', handleFocusOut)
            tabTrap.closest('.nav_menu_el').querySelector('button').focus()
            // Cerrar el submenú
            tabTrap.classList.remove('act')
            lastFocus.focus()
            if (!submenu) {
              console.log('jandermore')
              this.removeFn()
              this.closeFn(nav)
            } else {
              tabTrap.classList.remove('act')
            }

          }
        }

        const handleFocusOut = (event) => {
          if (!tabTrap.contains(event.relatedTarget)) {
            tabTrap.removeEventListener('keydown', handleKeyDown)
          }
        }

        tabTrap.addEventListener('keydown', handleKeyDown)
        tabTrap.addEventListener('focusout', handleFocusOut)

        // Enfocar el primer elemento del submenú
        if (firstElement) {
          firstElement.focus()
        }
      }
    }
  }



  async closeFn(nav) {

    //-1 para desactivar el click ( para no meter un isloading)
    this.isOpen = -1

    document.documentElement.classList.remove('A-nav')

    // this.removeFn()
    for (let el of nav.querySelectorAll('*')) {
      if (el.classList.contains('act')) el.classList.remove('act')
      if (el.classList.contains('hide')) el.classList.remove('hide')
    }


    this.EVT.get('SLL').state = 1
    document.dispatchEvent(this.EVT.get('SLL'))
    this.isOpen = 0
  }
  async openFn(nav) {
    // PARA SCROLL
    this.EVT.get('SLL').state = 0
    document.dispatchEvent(this.EVT.get('SLL'))


    this.isOpen = 1
    this.ANMbrg.play()
    document.documentElement.classList.add('A-nav')
  }

  killEVT(DOM = undefined) {
    // 💡 Eliminar EVT NAV

    // if(DOM == undefined){
    //   DOM = new Map([
    //     ['nav', nav],
    //     ['head', head],
    //     ['brg', brg],
    //     ['navOps', navOps]
    //   ])
    // }




  }



  // updFn(VEL = 0){
  // 💡 UPD NAV

  // }

  resizeFn(ops) {
    // 💡 Resize NAV


    this.closeFn(document.querySelector('.nav'))
    this.set(ops, [document.querySelector('.nav'), document.querySelector('.head')])
  }
}