

// import Titles from './0intro/IOtitles.js'

export const IOSlegal = {

  // 'titles':(el)=>  new Titles(el)
}

import Intro from './0intro/legalintro.js'

export const CMPlegal = {

  'intro':(el)=>  new Intro(el),

}