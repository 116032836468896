import * as lib from '/front/js🧠🧠🧠/🔧utils.js'


export function setRVL(ops = null) {
  // 💡💡💡
  // SET LOADER, especifica contenidos, crea animación entrada y salida.

  const el = document.querySelector('.ldr')
  const logo = el.querySelector('.ldr_i')
  const logoL = el.querySelector('.ldr_i_pl svg')
  const logoR = el.querySelector('.ldr_i_pr svg')
  const text = el.querySelector('.ldr_t')
  const spans = el.querySelectorAll('.ldr_t span')

  // ANM de entrada ⬅️
  const ANMstart = gsap.timeline({ paused: true })
    // 💡 El waiter es para que no se vean mierdas raras, se elimina y listo.
    .to(document.querySelector('.waiter'), {
      opacity: 0, duration: .2, delay: .01,
      onComplete: () => {
        document.querySelector('.waiter').remove()
      }
    }, 0)

  const ease = 'Power2.inOut'
  let duration = .8

  ANMstart
    .to(logoL, {
      xPercent: -50, duration, ease
    }, 0)
    .to(logoR, {
      xPercent: 50, duration, ease
    }, 0)
    .fromTo(logo, {
      x: text.clientWidth * .5 + 'px'
    }, {
      x: 0, duration: .8, ease: 'Power2.inOut'
    }, .8)

  for (let [i, a] of spans.entries()) {
    ANMstart
      .to(a, {
        yPercent: -110, duration, ease, delay: 1.2
      }, i * .2)
  }




  // END ANIM
  const ANMend =
    gsap.timeline(
      {
        paused: true,
        onComplete: () => {
          el.remove()
        }
      })
      .to(el, {
        '--LDRt': '0%', duration: 1, ease
      }, 1)
      .to(el, {
        '--LDRb': '0%', duration: 1.2, ease
      }, 1)
      .to(el, {
        '--LDRx': '-25%', duration: 1, ease
      }, .9)


  //💡 ACELERAR LOADER PA QUE NO DE POR CULO  
  if (import.meta.env.DEV == true) {
    ANMstart.timeScale(3)
    ANMend.timeScale(3)
  }
  ANMstart.timeScale(6)
  ANMend.timeScale(6)

  const RVL = new Map([
    ['el', el],
    ['ANMin', ANMstart],
    ['ANMout', ANMend],
  ])

  return RVL
}

export async function initRVL(RVL, resolve = null) {
  // 💡💡💡
  // INIT LOADER : Lanzaría la primera animación.
  await RVL.get('ANMin').play()


  // por si viene un resolve, pero solo se suele usar en POP, no en LDR
  resolve != null ? resolve() : null


}


// END LOADER
export async function endLDR(RVL) {
  // 💡💡💡
  // END LOADER : animación de salida.
  const el = RVL.get('el')


  await RVL.get('ANMout').play()
  // await lib.timeout(900) o await a la animación

  return RVL
}