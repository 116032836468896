

export default class Lazy{
  constructor (el) {

    const parent = el.parentNode
    this.MD = parent.querySelector('video') ?? parent.querySelector('img')
    this.type = this.MD.tagName == 'VIDEO' ? 'VID' : 'IMG' 
    this.load = 0
    
  }
  
  set(ops){

    if(this.type=='VID'){
      this.touch = ops.get('touch')
      
    
      if(this.MD.dataset.auto){
        this.auto = 1
        if(this.MD.src || this.MD.dataset.src){
          this.load = 1
          return false
        }
        
        this.MD.src = 'data:,'

        this.MD.loop = true
        this.MD.muted = true

          this.MD.setAttribute('webkit-playsinline', 'webkit-playsinline')
          this.MD.setAttribute('playsinline', 'playsinline')
        
          if(this.touch==true){
            
            this.MD.load()
          }
      
        }
      
    }

    
  }

  kill(){
    
    if(this.type=='VID'){
      this.MD.pause()
    }
  
  }

  async chkFn(entry,pos){
    let CHK = false
    if(entry.isIntersecting==undefined){
      

      
    }
    else if(entry.isIntersecting==true){
      
      
      CHK = this.start()

    }
    else{

      CHK = this.stop()

    }

    return CHK

   

  }
  
  start(){
    this.active = 1
    if(this.load == 0){
      this.onLoad()
    }
    else if(this.load == 1 && this.auto){

      this.MD.play()
    }
    return true
  }
  
  stop(){

    this.active = 0
    if(this.type =='VID'){
      let isPlaying = this.MD.currentTime > 0 && !this.MD.paused && !this.MD.ended 
      && this.MD.readyState > this.MD.HAVE_CURRENT_DATA
      
      const pause = isPlaying ? this.MD.pause() : undefined
    
    }
   
    return false
  }

  async onLoad(){
    this.load = -1

    //🦶 Load para el LAZY
    if(this.type == 'IMG'){

      this.MD.dataset.src = this.MD.dataset.lazy
      delete this.MD.dataset.lazy
      this.loadFn(this.MD)
      .then(()=>{
        // this.okFn()
        this.load = 1
      })
      
    }
    else if(this.type == 'VID'){
      if(this.MD.classList.contains('WT')){
        
        return false
      }

      this.MD.dataset.src = this.MD.dataset.lazy
      delete this.MD.dataset.lazy
      this.loadFn(this.MD)
      .then(()=>{
        this.okFn()
        this.load = 1
        // this.MD.setAttribute('autoplay', 'true')
        this.MD.play()
      })
      
    }

  }

  
}
