

import Introio from './0intro/IOhome.js'


export const IOShome = {

  'home':(el)=>  new Introio(el),
}
import Intro from './0intro/homeintro.js'
import Fake from './0intro/killme.js'


export const CMPhome = {

  'intro':(el)=>  new Intro(el),
  'fake':(el)=>  new Fake(el),

}