import * as lib from '/front/js🧠🧠🧠/🔧utils.js'


export default class Sll{
  constructor (el) {



    this.el = el
    this.parent = this.el.parentNode
    this.upd = 'UPD'

  }
  
  set(OPS){

      
      this.setBND()
      
        const line = this.parent.querySelector('.line')

        
        const ease = 'Power3.out'
        const duration = 1

        this.ANM = gsap.timeline({paused:true})
        .fromTo(line,{scaleX:0},{scaleX:1,duration,ease}, 0)

      

      
    
  }
  kill(){
    // 💡💡💡 Mata todos los procesos del IO
    this.active = 0
    // this.killEVT()
    
  }

  chkFn(entry,pos){
    let CHK = false
    
    if(entry.isIntersecting==undefined){
      //🦶 aquí incluso se puede hacer algo por si se esconde un IO ( para eso el height 0)
      
      CHK = undefined
    }
    else if(entry.isIntersecting==true){
      //🦶 IN
      CHK = this.start(entry)

    }
    else{
      //🦶 OUT
      CHK = this.stop(entry)

    }

    return CHK


  }
  
  start(){
    if(this.active == 1){
      return true
    }
    this.active = 1
   
    return true
  }
  
  stop(entry){
    // 💡💡💡
    // Por si el lerp no ha terminado, la irse de pantalla
    let PRG = 0
    let CRT = 0

    if(this.active == 0){
      return false
    }
    this.active = 0
   
    if(entry.boundingClientRect.y < 0){
      PRG = 1
      CRT = this.BND.get(1)

    }
    else{
      PRG = 0
      CRT = this.BND.get(0)
      
    }

    gsap.to(this.ANM,{progress:PRG,duration:.2,

      onComplete:()=>{
        this.BND.set('CRT',CRT)

        this.BND.set('PRG',PRG)
        this.BND.set('PRGt',PRG)
        
      }
    })

    return false
  }


  // Scroll Y y Velocidad del Wheel
  updFn(SCLLy = window.scrollY, VEL){
    if(this.active == 0 || !this.ANM){
      return false
    }

    // Método nuevo, si está el scroll parado, el update te devuelve el scroll en false,
    // Esto lo usamos por si no queremos sobrecargar mucho el update y podemos parar ciertos métodos ( sobretodo en 3dGL )
    // SCLLy == false ? window.scrollY : SCLLy

    
    if(SCLLy != false){
      //🦶 Aligeramos pasos, y lo que nos interesa en el clamp es lo 2º, el 1º y el 3º son limits
      
      this.BND.set('CRT',
      lib.clamp(
        0,
        this.BND.get(1),
        (SCLLy + this.SCR[1]) - this.BND.get(0),
      ))
      
      //🦶 calc progreso, el fixed 8 es porque si no da saltitos, a las muy malas, quitar
      this.BND.set('PRGt',
      parseFloat(
        (
          this.BND.get('CRT')  / this.BND.get(1)
        )
      ))

    }
    
    // Para no estar lanzando todo el rato una fn que no hace falta ejecutar
    if(this.BND.get('PRG') == this.BND.get('PRGt')) return false
    
    this.BND.set('PRG',
    (lib.lerp(
      this.BND.get('PRG') , 
      this.BND.get('PRGt') , 
      
      .008
    ))
    )
    
     this.ANM.progress( this.BND.get('PRG') )
 
   }
  setBND(SCLLy = window.scrollY){
    //🦶 Posiciones, tamaños, etc
    // Start 0         End (limit )    1    
    // current CRT     Progress PRG        
    const h = window.innerHeight
    const BND = this.el.getBoundingClientRect()
    const start = parseInt((BND.y + SCLLy))
    const limit =  BND.height

    this.BND = new Map([
      [0,start],
      [1,limit],
      ['CRT',0],
      ['PRG',0]
    ])

    this.SCR = [window.innerWidth,window.innerHeight]

  }
  resizeFn(SCLLy = window.scrollY,OPS){
    // 💡💡💡 


    if(this.ANM){
      
      this.set(OPS)
      this.updFn(SCLLy,0)
      
    }

    
  }
  
}
